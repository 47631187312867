import { Web3Provider } from "@ethersproject/providers";
import { ethers, BigNumber } from "ethers";
import RootkitsForRunnersAbi from "./abi/RootkitsForRunners.json";

export type ManifestAttribute = {
  trait_type: string;
  value: string;
}

// new address, updated 07dec
export const RINKEBY_ADDRESS = "0x46af1aaabbdab9675f87118df506b88345b1c44c";
// populate this with the address once it's deployed
export const MAINNET_ADDRESS = "0xA138cC52e9baA400c1105Debb42904AFD9cbFB59";
// make sure this is updated if you ever update the contract value
export const MINT_COST_ETH = 0.01;

export const getContractAddress = (): string => {
  switch(process.env.REACT_APP_NETWORK){
    case "mainnet":
    case "MAINNET":
      console.log('fetching contract address',MAINNET_ADDRESS);
      return MAINNET_ADDRESS;
    default:
      console.log('fetching contract address',RINKEBY_ADDRESS);
      return RINKEBY_ADDRESS;
  }
}

export const buildContractInstance = (provider: Web3Provider): ethers.Contract => {
  return new ethers.Contract(getContractAddress(), RootkitsForRunnersAbi, provider);
}

export const verifyHolder = async (provider: Web3Provider): Promise<boolean> => {
  const contract = buildContractInstance(provider);
  const balance = await contract.balanceOf(await provider.getSigner().getAddress());
  console.log('balance',balance);
  return BigNumber.from(0).lt(BigNumber.from(balance));
}

export const getAttributesFromTokenLists = (manifestList: any[]|undefined, runnerList: any[]|undefined): string[] => {
  let attributeList: string[] = [];
  if(manifestList){
    for(const manifest of manifestList){
      const metadata = JSON.parse(manifest.metadata);
      attributeList = attributeList.concat(metadata?.attributes.map((item: {trait_type: string, value: string}) => item.value.toLowerCase()));
    }
  }
  if(runnerList){
    for(const runner of runnerList){
      // TODO
    }
  }
  console.log('return attributelIst',attributeList);
  return attributeList;
}
