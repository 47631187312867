
export enum ProgressCategory{
  GUARDS = 'guards',
  HARDWARE = 'hardware',
  CRATES = 'crates'
}
export type Story = {
  title?: string;
  steps: StoryStep[];
  namedSteps?: {[key: string]: StoryStep};
  completion: CompletionObject,
  methods?: { [key: string]: () => unknown },
}
export type StoryStep = {
  text: string|string[];
  responses: StoryResponse[];
}
export type StoryResponse = {
  value: string|null;
  action: string;
  text?: string|string[];
}
export type CompletionObject = {
  text: string;
  poapData: string;
  progress: { section: ProgressCategory, value: number };
}