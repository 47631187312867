import React, { useState } from 'react';
import './App.css';
import HeistTerminal from './components/HeistTerminal';
import Terminal from './components/Terminal';
import { Web3Provider } from "@ethersproject/providers";

const welcomeMessage = `bootjack.exe initialized. 
This is an unauthorized Somnet protocol. 
Use at your own risk.
`;

const bannerCondensed = 
" _                 _   _            _    ____   ___ \n"+  
"| |               | | (_)          | |  |___ \\ / _ \\ \n"+
"| |__   ___   ___ | |_ _  __ _  ___| | __ __) | | | |\n"+
"| '_ \\ / _ \\ / _ \\| __| |/ _` |/ __| |/ /|__ <| | | |\n"+
"| |_) | (_) | (_) | |_| | (_| | (__|   < ___) | |_| |\n"+
"|_.__/ \\___/ \\___/ \\__| |\\__,_|\\___|_|\\_\\____(_)___/ \n"+
"                     _/ |                            \n"+
"                    |__/                             \n";

const prompt = ">";

const App = () => {
  const [isHeist, setIsHeist] = useState(false);
  const [provider, setProvider] = useState<Web3Provider>();

  if(isHeist){
    return ( 
      <HeistTerminal 
        welcomeMessage={welcomeMessage}
        banner={bannerCondensed}
        setIsHeist={setIsHeist}
        provider={provider}
      />
    );
  }
  return (
    <Terminal
      welcomeMessage={welcomeMessage}
      banner={bannerCondensed}
      terminalPrompt={prompt}
      setIsHeist={setIsHeist}
      provider={provider}
      setProvider={setProvider}
    />
  )
}

export default App;
