import { BootjackerStory } from "./bootjacker";
import { Story, StoryResponse } from './story-types';
import {
  DoritosDust,
  DoritosHqKeyCard,
  DoritosPolo,
  DoritoWindbreaker
} from './bypass-guards';
import {
  BashBunny,
  CodeBomb,
  RaspberryPi3,
  LongRangeAntenna
} from './plant-hardware';
import {
  DoritosDeliveryTruck,
  DoritoHoodie,
  DoritosClipboard,
  CoolRanchDoritos
} from './unload-crates';

export const getMissionStory = (storyName: string) => {
  switch(storyName){
    // bypass guards
    case "doritos dust":
      return DoritosDust;
    case "doritos hq key card":
      return DoritosHqKeyCard;
    case "doritos polo":
      return DoritosPolo;
    case "dorito windbreaker":
      return DoritoWindbreaker
    // plant hardware
    case "bash bunny":
      return BashBunny;
    case "code bomb":
      return CodeBomb;
    case "raspberry pi 3":
      return RaspberryPi3;
    case "long range antenna":
      return LongRangeAntenna;
    // unload crates
    case "doritos delivery truck":
      return DoritosDeliveryTruck;
    case "dorito hoodie":
      return DoritoHoodie;
    case "doritos clipboard":
      return DoritosClipboard;
    case "cool ranch doritos":
      return CoolRanchDoritos;
    // default option
    case 'bootjacker':
      return BootjackerStory;
    default:
      // returns undefined
      return;
  }
}

export const handleStoryInput = (activeStory: Story, storyIndex: number, activeNamedStep: string|undefined, input: string): StoryResponse => {
  const lowerInput = input ? input.toLowerCase() : '';
  // if we're on a named step, use that
  if(activeNamedStep && activeStory.namedSteps){
    console.log('active story',activeStory);
    console.log('named step',activeNamedStep);
    console.log('input',input);
    for(let responseItem of activeStory.namedSteps[activeNamedStep].responses){
      // console.log('response item',responseItem);
      // console.log(`compare ${lowerInput} to ${responseItem.value?.toLowerCase()}: ${lowerInput===responseItem.value?.toLowerCase()}`);
      // the wildcard value for the responseItem value will always trigger
      if(lowerInput===responseItem.value?.toLowerCase() || responseItem.value==='*'){
        return responseItem;
      }
    }
  }
  const activeStep = activeStory.steps[storyIndex];
  for(let responseItem of activeStep.responses){
    if(lowerInput===responseItem.value?.toLowerCase()){
      return responseItem;
    }
  }
  return { value: null, action: 'error'};
}

const getItemCategoryFromStoryName = (storyName: string): string|null => {
  const categoryMap = {
    loot: ['Cool Ranch Doritos'],
    gear: ['Doritos Clipboard', 'Doritos Windbreaker', 'Code Bomb'],
    clothing: ['Doritos Hoodie', 'Doritos Polo'],
    vehicle: ['Doritos Delivery Truck'],
    contraband: ['Doritos Dust'],
    hardware: ['Doritos HQ Key Card', 'Bash Bunny', 'Raspberry Pi 3', 'Long-Range Antenna']
  }
  console.log('check storyname',storyName);
  if(categoryMap.loot.indexOf(storyName)>-1) return 'loot';
  if(categoryMap.gear.indexOf(storyName)>-1) return 'gear';
  if(categoryMap.clothing.indexOf(storyName)>-1) return 'clothing';
  if(categoryMap.vehicle.indexOf(storyName)>-1) return 'vehicle';
  if(categoryMap.contraband.indexOf(storyName)>-1) return 'contraband';
  if(categoryMap.hardware.indexOf(storyName)>-1) return 'hardware';
  return null;
}

// TODO check how this actually sees the lists that are passed into it based upon Steve's
// testing. or if i generate my own
// right now having more (wrong) answers after the valid answer will blow the valid answer out
// obviously that's bad
export const checkCoordinates = (storyName: string, input: string, manifestList: any[]): boolean => {
  // dev bypass so you can test stories you don't hold
  if(process.env.NODE_ENV==='development' && input==='bypass') return true;
  console.log('manifest list',manifestList);
  // extract the coordinates relevant to the story
  // const manifestDataList = manifestList.map(item => JSON.parse(item.metadata));
  // console.log('checking manifest data from your list',manifestDataList);
  const manifestAttributeLists = manifestList.map(item => JSON.parse(item.metadata)).map(item => item.attributes);
  // based upon story we can determine which manifest has the relevant information
  const itemCategory = getItemCategoryFromStoryName(storyName);
  if(itemCategory){
    // const matchingList = manifestDataList.filter((item) => item[itemCategory]===storyName);
    // console.log('we should only review records from',matchingList);
    // const testableAttributeList = matchingList.map(item => item.attributes);
    console.log('check location from list for category',itemCategory);
    return matchLocationFromList(itemCategory, storyName, input, manifestAttributeLists);
  }
  return false;
}

export const matchLocationFromList = (keyTrait: string, traitValue: string, input: string, manifestList: {trait_type: string, value: string}[][]): boolean => {
  console.log(`matching location for key: ${keyTrait}, trait: ${traitValue}, and input ${input}`);
  console.log('attributeList',manifestList);
  let validResponses: string[] = [];
  for(let manifestAttributes of manifestList){
    let matchingManifest=false;
    let manifestDestination;
    // let manifestLocation;

    for(let attribute of manifestAttributes){
      if(attribute.trait_type===keyTrait && attribute.value===traitValue){
        matchingManifest=true;
      }
      // if(attribute.trait_type==="location"){
      //   manifestLocation=attribute.value;
      // }
      if(attribute.trait_type==="destination"){
        manifestDestination=attribute.value;
        validResponses.push(attribute.value);
      }
    }

    if(matchingManifest && manifestDestination){
      // console.log(`checking input ${input} against ${manifestDestination} or ${manifestLocation}`);
      // return (manifestDestination===input || manifestLocation===input);
      validResponses.push(manifestDestination);
    }
  }
  // NOTE dev override -> always true
  if(process.env.NODE_ENV==='development') return true;
  return validResponses.indexOf(input)>-1;
}