import { ProgressCategory, Story } from "./story-types";

const THATS_A_SHAME = "That’s a shame, runner. We thought we could depend on you, but it looks like our assumptions were wrong. Ping me if you change your mind… but I can’t guarantee you’ll get a response. Stay vigilant, runner. Somnites are lurking everywhere";

const commonSteps = {
  headIn: {
    text: "Head to the hideout and download your instructions from maraj [y/n]",
    responses: [
      { value: 'y', action: 'advance' },
      { value: 'n', action: 'twitter' }
    ]
  },
  greeting: {
    text: "Welcome, Runner. I see you've been doing some bootjacking. Find anything interesting? [y/n]",
    responses: [
      { value: 'y', action: 'advance' },
      { value: 'n', action: 'fail', text: THATS_A_SHAME }
    ]
  },
  chooseMethod: {
    text: "Okay I know where I'm going, but I need a strategy. I'll be rolling solo, so I can either use my cunning wit and COSPLAY A SOMNITE, or exercise brute force and pull off a classic SMASH AND GRAB.",
    responses: [
      { value: 'cosplay a somnite', action: 'namedStep: cosplay a somnite' },
      { value: 'smash and grab', action: 'namedStep: smash and grab' }
    ]
  },
}

export const CoolRanchDoritos: Story = {
  title: 'Cool Ranch Doritos',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Excellent. We need all the help we can get with this one. So, I see you were able to bootjack a shipping manifest. Did you see where they are sending the COOL RANCH DORITOS? [y/n]",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Very good. We’re sending a small team of Renegade operatives into the Chip Factory to UNLOAD THE CRATES and heist the loot. We need to cop a DORITOS to help fuel our operatives. Go grab some and meet me back here. Be careful out there, Runner. Somnites are lurking everywhere." },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': {
      text: [
        "I’ll wait til the cover of darkness and then maneuver into the location to scope it out.",
        "SMASH...",
        "GRAB...",
        "RUN...",
        "Oh, shit. The Somnites are on my tail.",
        "[DUCK AND HIDE / KEEP RUNNING]"
      ],
      responses: [
        { value: 'duck and hide', action: 'namedStep: decision' },
        { value: 'keep running', action: 'fail' }
      ]
    },
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "These COOL RANCH DORITOS could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN THEM IN / KEEP THEM]"
      ],
      responses: [
        { value: 'turn them in', action: 'completion' },
        { value: 'keep them', action: 'fail', text: "You take a grim satisfaction in your delicious Doritos, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters. ",
        "The COOL RANCH DORITOS is in sight",
        "/enact cloak",
        "Security disabled...",
        "Pending authorization…",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "These COOL RANCH DORITOS could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…"
      ],
      responses: [
        { value: 'turn them in', action: 'completion' },
        { value: 'keep them', action: 'fail', text: "You take a grim satisfaction in your delicious Doritos, knowing you've betrayed Maraj's trust." }
      ]
    }
  },
  completion: {
    text: "Maraj: Well done, runner. This is not small contribution. Lookc forward to working with you again soon.",
    poapData: "Collect your reward at Decentraland Coordinates x, y",
    progress: { section: ProgressCategory.CRATES, value: 5 }
  }
}

export const DoritosClipboard: Story = {
  title: 'Doritos Clipboard',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Excellent. We need all the help we can get with this one. So, I see you were able to bootjack a shipping manifest. Did you see where they are sending the DORITOS CLIPBOARD?",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Very good. We’re sending a small team of Renegade operatives into the Chip Factory to UNLOAD THE CRATES and heist the loot. We need to cop a CLIPBOARD to help disguise our operatives. Go grab one and meet me back here. Remember, if you catch any heat, just lay low. Be careful out there, Runner. Somnites are lurking everywhere." },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': {
      text: [
        "I’ll wait til the cover of darkness and then maneuver into the location to scope it out.",
        "SMASH...",
        "GRAB...",
        "RUN...",
        "Oh, shit. The Somnites are on my tail.",
        "[DUCK AND HIDE / KEEP RUNNING]"
      ],
      responses: [
        { value: 'duck and hide', action: 'namedStep: decision' },
        { value: 'keep running', action: 'fail' }
      ]
    },
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This DORITOS CLIPBOARD could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN THEM IN / KEEP THEM]"
      ],
      responses: [
        { value: 'turn them in', action: 'completion' },
        { value: 'keep them', action: 'fail', text: "You stash the CLIPBOARD for future use, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters. ",
        "The DORITOS CLIPBOARD is in sight",
        "/enact cloak",
        "Security disabled...",
        "Pending authorization…",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "These DORITOS CLIPBOARD could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…"
      ],
      responses: [
        { value: 'turn them in', action: 'completion' },
        { value: 'keep them', action: 'fail', text: "You take a grim satisfaction in your delicious Doritos, knowing you've betrayed Maraj's trust." }
      ]
    }
  },
  completion: {
    text: "Well done, runner. This is no small contribution. Look forward to working with you again soon. Keep an eye on your coms. We’ll ping you with further instructions.",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.CRATES, value: 5 }
  }
}

export const DoritoHoodie: Story = {
  title: 'Dorito Hoodie',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Excellent. We need all the help we can get with this one. So, I see you were able to bootjack a shipping manifest. Did you see where they are sending the DORITO HOODIES?",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Very good. We’re sending a small team of Renegade operatives into the Chip Factory to UNLOAD THE CRATES and heist the loot. We need to cop these hoodies to help disguise our operatives. Go grab one and meet me back here. Remember, if you catch any heat, just lay low. Be careful out there, Runner. Somnites are lurking everywhere." },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': {
      text: [
        "I’ll wait til the cover of darkness and then maneuver into the location to scope it out.",
        "SMASH...",
        "GRAB...",
        "RUN...",
        "Oh, shit. The Somnites are on my tail.",
        "[DUCK AND HIDE / KEEP RUNNING]"
      ],
      responses: [
        { value: 'duck and hide', action: 'namedStep: decision' },
        { value: 'keep running', action: 'fail' }
      ]
    },
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This DORITO HOODIE could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN THEM IN / KEEP THEM]"
      ],
      responses: [
        { value: 'turn them in', action: 'namedStep: transit' },
        { value: 'keep them', action: 'fail', text: "You stash the DORITO HOODIE for future use, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters. ",
        "The DORITO HOODIE is in sight",
        "/enact cloak",
        "Security disabled...",
        "Pending authorization…",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "These DORITO HOODIE could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…"
      ],
      responses: [
        { value: 'turn them in', action: 'namedStep: transit' },
        { value: 'keep them', action: 'fail', text: "You take a grim satisfaction in your ill begotten hoodie, knowing you've betrayed Maraj's trust." }
      ]
    },
    'transit': {
      text: [
        "Maraj: “The truck is headed to the rendezvous point in an hour.  Put on this Doritos Hoodie and jump in the back. At the factory, act natural and unload the crates” ",
        "“Be careful out there, runner. These are not your typical Somnites if they’re moving this kind of firepower. Get the coordinates from The Hideout pin board. Once you get there, ping me on your Somcom.”",
        "Enter the coordinates for the rendezvous point:"
      ],
      responses: [
        { value: '[651a 921b]', action: 'namedStep: arrival' },
        { value: '*', action: 'namedStep: retryTransit' } // wildcards have to go last
      ]
    },
    'retryTransit': {
      text: [
        "Incorrect. Try again: coordinates format [a b]"
      ],
      responses: [
        { value: '[651a 921b]', action: 'namedStep: arrival' },
        { value: '*', action: 'namedStep: retryTransit' } // wildcards have to go last
      ]
    },
    'arrival': {
      text: [
        "Transporting...",
        "Arrived location [651a 921b]",
      ],
      responses: [
        { value: 'bail', action: 'fail' },
        { 
          value: 'jump in the back', 
          action: 'completion', 
          text: [
            "Shit, I hope Maraj is right about this.",
            "In transit...",
            "Arriving",
            "Beep. Beep. Beep.",
            "Doors open...",
            "I guess Maraj was right afterall. They're going to just hand over the loot and let us leave. We really do have control of this place. Amazing…",
            "Exiting swiftly",
            "Done"
          ]
        }
      ]
    }
  },
  completion: {
    text: "Well done, runner. This is no small contribution. Look forward to working with you again soon. Keep an eye on your coms. We’ll ping you with further instructions.",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.CRATES, value: 5 }
  }
}

export const DoritosDeliveryTruck: Story = {
  title: 'Doritos Delivery Truck',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: [
        "Maraj: “Very good. You’re going to need to steal that truck. We’ll be sending you and a small team of Renegade operatives into the Chip Factory to UNLOAD THE CRATES and heist the loot.”",
        "“Your mission, should you choose to accept it, is to meet the Renegade Operatives at the RENDEZVOUS POINT, Bypass the guards at THE CHIP FACTORY, load the crates of HEISTED LOOT, and return to THE HIDEOUT.”",
        "Do you accept? [Y/N]"
      ],
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Excellent, runner. I knew we could count on you. Go get the truck. I don’t need to know how you do it. Ping me on your Somcom when you have it. Good Luck out there. And REMEMBER, it’s always smart to lay low if the heat gets too hot.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': {
      text: [
        "I’ll wait til the cover of darkness and then maneuver into the location to scope it out.",
        "SMASH...",
        "GRAB...",
        "RUN...",
        "Oh, shit. The Somnites are on my tail.",
        "[DUCK AND HIDE / KEEP RUNNING]"
      ],
      responses: [
        { value: 'duck and hide', action: 'namedStep: decision' },
        { value: 'keep running', action: 'fail' }
      ]
    },
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This DORITOS TRUCK could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN THEM IN / KEEP THEM]"
      ],
      responses: [
        { value: 'turn them in', action: 'namedStep: transit' },
        { value: 'keep them', action: 'fail', text: "You stash the DORITOS TRUCK for future use, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters. ",
        "The DORITOS TRUCK is in sight",
        "/enact cloak",
        "Security disabled...",
        "Pending authorization…",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "These DORITO HOODIE could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN THEM IN / KEEP THEM]"
      ],
      responses: [
        { value: 'turn them in', action: 'namedStep: transit' },
        { value: 'keep them', action: 'fail', text: "You take a grim satisfaction in your ill begotten hoodie, knowing you've betrayed Maraj's trust." }
      ]
    },
    'transit': {
      text: [
        "Maraj: “You’ll need to be well disguised to get past the Somnites. We’ve got some chip company gear that was lifted by our fellow Renegades. Put on this Doritos Windbreaker and no one will ever be the wiser. I hope...” ",
        "“Be careful out there, Renegade. These are not your typical Somnites if they’re moving this kind of firepower. Get the coordinates for the rendezvous point from The Hideout HACKER ROOM. Once you’re in position, ping me on your Somcom and await further instructions.”",
        "Enter the coordinates for the rendezvous point:"
      ],
      responses: [
        { value: '[651a 921b]', action: 'namedStep: arrival' },
        { value: '*', action: 'namedStep: retryTransit' } // wildcards have to go last
      ]
    },
    'retryTransit': {
      text: [
        "Incorrect. Try again: coordinates format [a b]"
      ],
      responses: [
        { value: '[651a 921b]', action: 'namedStep: arrival' },
        { value: '*', action: 'namedStep: retryTransit' } // wildcards have to go last
      ]
    },
    'arrival': {
      text: [
        "Truck in transit...",
        "Arrived location [651a 921b]",
        "Picking up operatives...",
        "In transit...",
        "Arriving: THE INDUSTRIAL SECTOR",
        "CHIP FACTORY LOCATED",
        "[PING MARAJ / JUST DRIVE IN]"
      ],
      responses: [
        { value: 'ping maraj', action: 'namedStep: pincard' },
        { value: 'just drive in', action: 'fail' }
      ]
    },
    'pincard': {
      text: [
        "Maraj: “Okay runner, when you get to the gate use this Key Card to pass security. If they ask for a pin code, it’s 5731. I’ll be running a jammer on the network to give you cover.”",
        "“Once you’re inside the gate, drive around back to the delivery dock. A Renegade disguised as a Chip Co Manager, COSMO, will be there waiting for you. Just look for the runner eating Doritos and carrying a clipboard. You can’t miss him. The situation on the ground is volatile. Until we can establish coms with the depot, we don’t know what we’re dealing with out there. Godspeed, Renegade. We’re counting on you.”",
        "Driving up to depot gates…",
        "Two heavily armed guards charge the vehicle.",
        "[GET THE HELL OUT / SLOW DOWN AND TALK]"
      ],
      responses: [
        { value: 'get the hell out', action: 'fail' },
        { value: 'slow down and talk', action: 'namedStep: getpin' }
      ]
    },
    'getpin': {
      text: [
        "Security: “Input your security pin, please”"
      ],
      responses: [
        { value: '5731', action: 'namedStep: checkname' },
        { value: '*', action: 'fail' }
      ]
    },
    'checkname': {
      text: [
        "Security: “Okay… who are you here to see?”"
      ],
      responses: [
        { value: 'Cosmo', action: 'namedStep: seemanager' },
        { value: '*', action: 'fail' }
      ]
    },
    'seemanager': {
      text: [
        "Security waves you through the gates.",
        "Shit, I thought we were gonners… Okay, we need to load these crates and get the hell out of here. I have a bad feeling about this place. Now where is this Cosmo… ",
        "Ah, Doritos bag and clipboard, this must be him…",
        "Shit, I hope Maraj is right about this guy. He sure does seem like a Somnite to me.  Am I sure this guy is trustworthy? He sure is acting strange. I have a bad feeling about this…",
        "[UNLOAD THE CRATES / GET THE HELL OUT]"
      ],
      responses: [
        { 
          value: 'unload the crates', 
          action: 'completion', 
          text: [
            "CHIP CO MANAGER approaches your window…",
            "Cosmo: “HELLO… er… Sir! We’ve been expecting you, of course.”",
            "“Back on up here and we’ll get those crates loaded for you.”",
            "I guess Maraj was right afterall. They're going to just hand over the loot and let us leave. We really do have control of this place. Amazing…",
            "Backing up the truck…",
            "Loading crates…",
            "Loading crates…",
            "…Exiting swiftly…",
            "Done."
          ]
        }, 
        { value: 'get the hell out', action: 'fail' }
      ]
    }
  },
  completion: {
    text: "Well done, runner. This is no small contribution. Look forward to working with you again soon. Keep an eye on your coms. We’ll ping you with further instructions.",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.CRATES, value: 5 }
  }
}