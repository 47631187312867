import React, { useState, useEffect, ReactElement } from "react";
import Banner from "./Banner";
import TerminalOutput from "./TerminalOutput";
import InputArea from "./InputArea";
import ErrorMessage from "./ErrorMessage";
import WelcomeMessage from "./WelcomeMessage";
import ObjectiveStatus from "./ObjectiveStatus";

import { Contract, ethers, providers } from 'ethers';
import { Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider"

import { buildContractInstance } from "../ContractUtil";

declare let window: any;

// make sure this is updated if you ever update the contract value
const MINT_COST_ETH = 0.01;

type TerminalProps = {
  terminalPrompt?: string;
  banner?: string;
  welcomeMessage?: string;
  setIsHeist: (newValue: boolean) => void;
  provider: Web3Provider|undefined;
  setProvider: (newValue: Web3Provider|undefined) => void;
};
const Terminal = (props: TerminalProps) => {
  const { terminalPrompt = ">", banner, welcomeMessage, provider, setProvider } = props;
  const [output, setOutput] = useState<(string | JSX.Element)[]>([]);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(3);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const [contractInstance, setContractInstance] = useState<Contract>();
  const [walletConnectProvider, setWalletConnectProvider] = useState<WalletConnectProvider>();
  const [audioElement, setAudioElement] = useState<HTMLAudioElement>();
  // track which input to show/take
  const [isMissionAcceptance, setIsMissionAcceptance] = useState(false);
  const [isHeist, setIsHeist] = useState(false);
  const [isHeistConfirmation, setIsHeistConfirmation] = useState(false);
  const [currentConfirmation, setCurrentConfirmation] = useState<string>();
  const [hackCount, setHackCount] = useState(1);
  const [timeOfDay, setTimeOfDay] = useState("day");
  // heist progression. may need to be smarter, but trying number for a starter.
  // the progress tracker values that will be pushed by completing different tasks
  const [objectiveProgress, setObjectiveProgress] = useState({guards: 0, hardware: 0, crates: 0});

  // only reason we change status is objective completion
  useEffect(() => {
    if(objectiveProgress.guards+objectiveProgress.crates+objectiveProgress.hardware > 0){
      handleHeistCommand('objective complete');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveProgress]);

  const getNetworkPrefix = (): string => {
    if(process.env.REACT_APP_NETWORK?.toLowerCase()==='rinkeby'){
      return 'rinkeby.';
    }
    return '';
  }

  // this is metamask
  const connectEthers = async (): Promise<Web3Provider> => {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    await window.ethereum.isConnected();
    const newProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(newProvider);
    setContractInstance(buildContractInstance(newProvider));
    return newProvider;
  }

  useEffect(() => {
    if(walletConnectProvider){
      connectWalletConnectProvider(walletConnectProvider)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletConnectProvider]);

  const connectWalletConnectProvider = async (wcProvider: WalletConnectProvider) => {
    if(provider){
      await wcProvider.disconnect();
    }
    try{
      await wcProvider.enable();
      console.log('wallet connect provider',wcProvider);
      const newProvider = new providers.Web3Provider(wcProvider);
      console.log('builds provider',newProvider);
      setProvider(newProvider);
      setContractInstance(buildContractInstance(newProvider));
      // here we print out walletconnect connection established.
      setOutput([
        ...output,
        (
          <div
            ref={(el) => (scrollRef.current = el)}
            className="terminal-command-record"
          >
            <span className="terminal-prompt">{terminalPrompt}</span>{" "}
            <span>connect walletconnect</span>
          </div>
        ),
        <div className="terminal-command-output">
            <div>
              <p>Connection established</p>
            </div>
          </div>
      ]);
    }catch(err){
      console.error(err);
      setOutput([
        ...output,
        (
          <div
            ref={(el) => (scrollRef.current = el)}
            className="terminal-command-record"
          >
            <span className="terminal-prompt">{terminalPrompt}</span>{" "}
            <span>connect walletconnect</span>
          </div>
        ),
        <div className="terminal-command-output">
          <ErrorMessage command={`Error connecting via walletconnect: ${err}`} />
        </div>
      ]);
      return;
    }
  }

  const connectWalletConnect = async ()=> {
    setWalletConnectProvider(new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_ID, // Required
      qrcode: true
    }));
  }

  // lets us clean up if the user disconnects midstream
  window.ethereum?.on('accountsChanged', (accounts: any[]) => {
    // console.log('change to accounts',accounts);
    if(accounts===[]){
      setProvider(undefined);
    }
    // Time to reload your interface with accounts[0]!
  });

  const scrollLastCommandTop = () => {
    scrollRef.current?.scrollIntoView();
  };

  useEffect(scrollLastCommandTop, [output]);

  const echoCommands = [
    "help",
    "about",
    "connect",
    "hack",
    "website",
    "play soundtrack",
    "stop soundtrack",
    "heist"
  ] as const;
  type EchoCommand = typeof echoCommands[number];
  const utilityCommands = ["clear"] as const;
  type UtilityCommand = typeof utilityCommands[number];
  const heistCommands = [
    "start heist",
    "bypass guards",
    "day",
    "night",
    "plant hardware",
    "unload crates"
  ] as const;
  type HeistCommand = typeof heistCommands[number];
  const allCommands = [...echoCommands, ...utilityCommands, ...heistCommands] as const;
  type Command = typeof allCommands[number];

  function isEchoCommand(arg: string): arg is EchoCommand {
    return (echoCommands as ReadonlyArray<string>).includes(arg);
  }

  function isUtilityCommand(arg: string): arg is UtilityCommand {
    return (utilityCommands as ReadonlyArray<string>).includes(arg);
  }

  function isHeistCommand(arg: string): arg is HeistCommand {
    return (heistCommands as ReadonlyArray<string>).includes(arg);
  }

  function isValidCommand(arg: string): arg is Command {
    return isEchoCommand(arg) || isUtilityCommand(arg) || isHeistCommand(arg);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const glow = (text: string) => {
    return <span className="terminal-glow">{text}</span>;
  };

  const commands: { [key in EchoCommand]: JSX.Element } = {
    help: (
      <div>
        <p>
          This site exists to allow you to "hack" your way to minting your NFT.
        </p>
        <dl>
          <dt>about</dt>
          <dd>Information about bootjack</dd>
          <dt>connect</dt>
          <dd>Connect your Web3 wallet to enable the bootjack protocol and hack arms shipment manifest(s) from the Somnet servers. Cost to hack is {MINT_COST_ETH} eth per manifest.</dd>
          <dt>hack</dt>
          <dd>Enter a bootjack key to "hack" (mint) one or more shipment manifests. Requires connected web3 wallet.</dd>
          <dt>heist</dt>
          <dd>Stage a heist against the Somnites with intelligence from Maraj.</dd>
          <dt>play soundtrack</dt>
          <dd>Listen to Vans in Japan by Deep State while securing your shipment manifests.</dd>
          <dt>stop soundtrack</dt>
          <dd>Stop the music</dd>
          <dt>website</dt>
          <dd>Learn more about this website</dd>
          <dt>clear</dt>
          <dd>Clears the terminal of all output</dd>
        </dl>
      </div>
    ),
    about: (
      <div>
        <p>
          Bootjack.exe is a Somnet hacking protocol developed by <a href="https://twitter.com/megacityarmory">Renegade Runners</a> for the purpose of undermining Somnus by participating in grey market heists and community coordinated hacks.
        </p>
      </div>
    ),
    connect: (
      <>
        Attempting to establish connection to your wallet...
      </>
    ),
    hack: (
      <>
        <p>Mint your shipment manifest by entering the quantity desired and a decryption key eg <pre>mint 5 10025</pre></p>
      </>
    ),
    website: (
      <>
        <p>
          This site was built using <a target="_blank" rel="noreferrer" href="https://craigfeldman.com/">Craig Feldman</a>’s terminal app in React. 
        </p>
        <p>Michael Feldstein designed the hack cipher and optimized the ERC721.</p>
        <p>The base ERC721 & art was developed by Steve Rex.</p>
        <p>The mint site was developed by Greg Syme.</p>
        <p>Content and lore was developed by OpenAI GPT3, seeded by cultural references identified by the Squid Queen, then curated and edited by members of the Mega City Builders guild.</p>
        <p>This project was incubated by Mega City Builders guild, a community of creators making cool stuff for Chain Runners.</p>
      </>
    ),
    "play soundtrack": (
      <>
        <p>Start Vans in Japan by <a href="https://thedeepstate.band/" target="_blank" rel="noreferrer">Deep State</a></p>
      </>
    ),
    "stop soundtrack": (
      <>
        <p>Stop Vans in Japan by <a href="https://thedeepstate.band/" target="_blank" rel="noreferrer">Deep State</a></p>
      </>
    ),
    heist: (
      <>
        <p>Attempt a daring raid on Somnet forces</p>
      </>
    )
  };

  const getCommandRecord = (input: string): ReactElement => {
    return (
      <div ref={(el) => (scrollRef.current = el)} className="terminal-command-record">
        <span className="terminal-prompt">{terminalPrompt}</span>
        <span>{input}</span>
      </div>
    )
  }

  const handleMissionAcceptance = (inputString: string) => {
    setIsMissionAcceptance(false);
    if(inputString==='y'){
      setIsHeist(true);
      printMenu('y');
    }else{
      window.location = "https://twitter.com/megamobileco";
    }
  }

  const printMenu = (input: string) => {
    const commandRecord = getCommandRecord(input);
    setOutput([
      ...output,
      commandRecord,
      <div className="terminal-command-output">
        {input!=='y' && <ErrorMessage command={input} heistError={true}/>}
        <p>
          Heist Menu
        </p>
        <dl>
          <dt>Mission Briefing</dt>
          <dd>Review the latest intelligence for the heist.</dd>
          <dt>Connect Wallet</dt>
          <dd>Verify hack eligibility based on your wallet contents (read only).</dd>
          <dt>Start Heist</dt>
          <dd>Take action to carry out the heist.</dd>
          <dt>Abort</dt>
          <dd>Exit the heist and return to the top level mint terminal.</dd>
        </dl>
      </div>
    ]);
  }

  const handleHeistCommand = (input: string) => {
    const commandRecord = getCommandRecord(input);

    let terminalOutput: JSX.Element;
    switch(input){
      case "abort":
        setIsHeist(false);
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <p>Exited heist.</p>
          </div>
        ]);
        return;
      case "mission briefing":
        terminalOutput = (
          <>
            <p style={{fontWeight: 900, textDecorationLine: "underline"}}>Background</p>
            <p>You wouldn’t know it by looking at it, but security is tight. Our suspicions have been proven correct. It seems Mega Mobile has converted this old chip factory into a temporary depot under the cloak of darkness to aid and abet the Somnites. And they’ve thrown their latest hardware and network tech at it to beef up security. They know we’re watching, so it’s not going to be easy.</p>
            <p style={{fontWeight: 900, textDecorationLine: "underline"}}>Trojan Horse</p>
            <p>Friendly operatives have staked out the local and observed Mega Mobile techs disguised as chip company employees. Our plan is to infiltrate their systems by sending in renegade runners disguised as chip company workers to blend in with the Somnites and take over operations from the inside.</p>
            <p>Once inside, runners will need to install a keylogger and bash bunny into the onsite mainframe and set up a long range antenna to bypass the firewall so we can receive their files in The Hideout.</p>
            <p>Once we have the files we’ll send in renegades disguised as doritos dock workers to unload the crates. Loot from the warehouse will be available to heist by any runner who hacked the corresponding manifest.</p>
            <p>Once we’re done all the renegades that help with the heist will get a participation trophy (in the form of a poap) and we’ll toss the keys to the factory to one of those poap holders.</p>
            <p style={{fontWeight: 900, textDecorationLine: "underline"}}>We’ll need help from the following runners and bootjackers:</p>
            <p>bootjack manifests:</p>
            <ul>
              <li>Key Logger</li>
              <li>Bash Bunny</li>
              <li>Code Bomb</li>
              <li>Raspberry Pi 3</li>
              <li>Long Range Antenna</li>
              <li>Doritos Polo</li>
              <li>Doritos Windbreaker</li>
              <li>Doritos Mega City HQ</li>
            </ul>
            <p>Chain Runners:</p>
            <ul>
              <li>Pyramid Cap</li>
              <li>Funky Headband</li>
            </ul>
          </>
        ) 
        break;
      case "connect wallet":
        if(provider){
          setIsHeistConfirmation(true);
          terminalOutput = (
            <>
              <p>Verified - Start Heist [y/n]</p>
            </>
          )
        }else{
          terminalOutput= (
            <>
              <p>
                Not Verified - Hack a Manifest / or 
                <a target="_blank" href="https://opensea.io/collection/bootjack" rel="noreferrer">Buy Manifest</a> or 
                <a href="https://opensea.io/collection/chain-runners-nft" target="_blank" rel="noreferrer">Buy Chain Runners</a>
              </p>
            </>
          )
        }
        break;
      case "start heist":
      case "objective complete":
        console.log('progress',objectiveProgress);
        terminalOutput = (
          <>
            <ObjectiveStatus 
              guards={objectiveProgress.guards} 
              hardware={objectiveProgress.hardware} 
              crates={objectiveProgress.crates}
            />
            <p>Heist Roles - BYPASS GUARDS, PLANT HARDWARE, UNLOAD CRATES</p>
          </>
        )
        break;
      case "bypass guards":
        setCurrentConfirmation('guards-brief');
        terminalOutput = (
          <p>
            Head to the hideout and download your instructions from Maraj? [y/n]
          </p>
        );
        break;
      case "plant hardware":
        if(objectiveProgress.guards<100){
          terminalOutput = (
            <p>
              We need to infiltrate the Somnite ranks via trojan horse.{' '}
              Recruit dorito runners and bootjackers to bypass the guards.
            </p>
          );
        }else{
          setCurrentConfirmation('hardware-brief');
          terminalOutput = (
            <p>Head to the hideout and download your instructions from Maraj? [y/n]</p>
          )
        }
        break;
      case "unload crates":
        if(objectiveProgress.guards<100){

        }
        if(objectiveProgress.hardware<100){
          
        }
        setCurrentConfirmation('crates');
        terminalOutput = (
          <p>Undefined [bzzzt. *explosion*]</p>
        )
        break;
      default:
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command="Invalid input" />
          </div>
        ]);
        printMenu(input);
        return;
    }
    setOutput([
      ...output,
      commandRecord,
      <div className="terminal-command-output">
        {terminalOutput}
      </div>
    ]);
  }

  const redirectToTwitter = () => {
    window.location = 'https://twitter.com/megamobileco';
  }

  const handleHeistConfirmation = (input: string) => {
    console.log('heist confirmation',input);
    setIsHeistConfirmation(false);
    if(input==='y'){
      // printMenu('y');
    }else{
      redirectToTwitter();
    }
  }
  // heist confirmations
  // guard confirmations
  const handleGuardBriefConfirmation = (input: string) => {
    setCurrentConfirmation(undefined);
    if(input==='y'){
      const commandRecord = getCommandRecord(input);
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          Maraj: welcome runner.  Congrats on joining the fight. This probably sounds crazy, but are you up for cosplaying as a somnite? If you're caught, then I can’t guarantee you’ll ever see Mega City again. [y/n]
        </div>
      ]);
      setCurrentConfirmation('guards-mission');
    }else{
      redirectToTwitter();
    }
  }

  const handleGuardMissionConfirmation = (input: string) => {
    setCurrentConfirmation(undefined);
    if(input==='y'){
      const commandRecord = getCommandRecord(input);
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <p>Good. You have two options: drive through the front door in the middle of the day or wait for the cover of night. </p>
          <p>If you go at night, it will look highly suspicious, but you may be able to bypass security quickly.</p>
          <p>If you go during the day, you had better have your acting skills ready to go full Somnite. What do you want to do? </p>
          <p>[night / day]</p>
        </div>
      ]);
      setCurrentConfirmation('guards-time');
    }else{
      redirectToTwitter();
    }
  }

  const handleGuardTimeSelection = (input: string) => {
    let terminalOutput;
    // night and day have the same impact
    if(input==='night' || input==='day'){
      setTimeOfDay(input);
      setCurrentConfirmation('guards-coordinates');
      // TODO catch coordinate input
      terminalOutput = (
        <>
          <p>Proceed with caution, runner. These are not your typical Somnites if they’re moving this kind of firepower. Here are the coordinates: 651a 921b. Once you get there, ping me on your Somcom and I’ll help you brute force the keypad.</p>
          <p>Enter coordinates:</p>
        </>
      )
    }else{
      terminalOutput = (<p>Not an option.</p>);
    }
    const commandRecord = getCommandRecord(input);
    setOutput([
      ...output,
      commandRecord,
      <div className="terminal-command-output">
        {terminalOutput}
      </div>
    ]);
  }

  const handleGuardsCoordinates = (input: string) => {
    if(input.indexOf("651a 921b")>-1){
      // success
      setOutput([
        ...output,
        getCommandRecord(input),
        <div className="terminal-command-output">
          <p>Transporting...</p>
        </div>
      ]);
      setTimeout(() => {
        setOutput([
          ...output,
          <div className="terminal-command-output">
            <p>Arrived location 651a 921b</p>
            <p>[ping maraj / just drive in]</p>
          </div>
        ]);
        setCurrentConfirmation('guards-on-location');
      }, 800);
    }else{
      setOutput([
        ...output,
        getCommandRecord(input),
        <div className="terminal-command-output">
          <p>Nothing but wasteland greets your eyes... did you take a wrong turn?</p>
        </div>
      ]);
    }
  }

  const handleGuardsOnLocation = (input: string) => {
    if(input==='just drive in'){
      setOutput([
        ...output,
        getCommandRecord(input),
        <div className="terminal-command-output">
          <p>You've been caught trespassing. Mission Failed.</p>
        </div>
      ]);
      setCurrentConfirmation(undefined);
    }else if(input==='ping maraj'){
      if(timeOfDay==='day'){
        setOutput([
          ...output,
          getCommandRecord(input),
          <div className="terminal-command-output">
            <p>okay runner, here’s the fun part - i’ll run the brute force while you jam the key pad.</p>
            <p>we have to be in unison or the whole thing will go to shit, fast.</p>
            <p>On the count of 3 enter 20 “1’s” into the keypad as fast as you can: 1…2…3!</p>
          </div>
        ]);
        setCurrentConfirmation('guards-bruteforce');
      }else{
        setOutput([
          ...output,
          getCommandRecord(input),
          <div className="terminal-command-output">
            <p>okay runner, here’s the fun part - you’re going to have to hack the keycode to get in the gate.</p>
            <p>We’ve been able to run a temporary security cloak, but it’s only good for 10 tries.</p>
            <p>After that, I can’t guarantee we’ll be able to help and you’ll have to live with the consequences. </p>
          </div>
        ]);
        setCurrentConfirmation('guards-hack');
      }
    }
  }

  const handleGuardsHack = (input: string) => {
    console.log('guard hack',input);
    const success = Number.parseInt(input)===1337;
    if(success){
      setHackCount(1);
      setOutput([
        ...output,
        getCommandRecord(input),
        <div className="terminal-command-output">
          <p>good work, renegade. Drive through the gate and park in employee parking. ACT NATURAL. If you have your chip gear on, then no one should suspect a thing.</p>
          <p>OBJECTIVE COMPLETE</p>
        </div>
      ]);
      setCurrentConfirmation(undefined);
      setObjectiveProgress({
        ...objectiveProgress,
        guards: objectiveProgress.guards+20
      });
    }else{
      setOutput([
        ...output,
        getCommandRecord(input),
        <div className="terminal-command-output">
          <p>Bzzt. Invalid combination. {10-hackCount} attempts remain.</p>
          { hackCount===9 && (
            <p>YOU'VE BEEN CAUGHT TRESPASSING</p>
          )}
        </div>
      ]);
      if(hackCount===10){
        setCurrentConfirmation(undefined);
        setHackCount(1);
      }
      setHackCount(hackCount+1);
    }
  }

  const handleGuardsBruteForce = (input: string) => {
    if(input==="1"){
      setHackCount(hackCount+1);
    }
    if(hackCount===21){
      // success
      setHackCount(1);
      setOutput([
        ...output,
        getCommandRecord(input),
        <div className="terminal-command-output">
          <p>good work, renegade. Drive through the gate and park in employee parking. ACT NATURAL. If you have your chip gear on, then no one should suspect a thing.</p>
          <p>OBJECTIVE COMPLETE</p>
        </div>
      ]);
      setCurrentConfirmation(undefined);
      setObjectiveProgress({
        ...objectiveProgress,
        guards: objectiveProgress.guards+20
      });
    }
    // check that it's not been too long, if we want to
  }

  // hardware confirmations
  const handleHardwareBriefConfirmation = (input: string) => {
    if(input==='y'){

    }else{
      redirectToTwitter();
    }
  }
  const handleHardwareMissionConfirmation = (input: string) => {
    if(input==='y'){

    }else{
      redirectToTwitter();
    }
  }
  const handleHardwareTimeSelection = (input: string) => {
    if(input==='y'){

    }else{
      redirectToTwitter();
    }
  }
  // crates confirmation
  const handleCratesBriefConfirmation = (input: string) => {
    if(input==='y'){

    }else{
      redirectToTwitter();
    }
  }
  const handleCratesMissionConfirmation = (input: string) => {
    if(input==='y'){

    }else{
      redirectToTwitter();
    }
  }
  const handleCratesTimeSelection = (input: string) => {
    if(input==='y'){

    }else{
      redirectToTwitter();
    }
  }


  const processCommand = async (input: string) => {
    // Store a record of this command with a ref to allow us to scroll it into view.
    // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.
    const commandRecord = getCommandRecord(input);

    // Add command to to history if the command is not empty
    if (input.trim()) {
      setHistory([...history, input]);
      setHistoryIndex(history.length + 1);
    }

    // Now process command, ignoring case
    let rawInput = input.toLowerCase().trim();
    let inputArg: string|null = null;
    let hackArg: string|null = null;
    let inputCommand;
    
    if(rawInput.startsWith('hack')){
      inputCommand = 'hack';;
      inputArg = rawInput.split(' ')[1]?.trim();
      hackArg = rawInput.split(' ')[2]?.trim();
    }else if (rawInput.startsWith('connect')){
      inputCommand = 'connect';
      inputArg = rawInput.split(' ')[1]?.trim(); 
    }else if(rawInput==='cm'){
      inputCommand = 'connect';
      inputArg = 'metamask';
    }else{
      inputCommand = rawInput;
    }
    // preprocessing done. determine what we're doing
    if(isMissionAcceptance){
      handleMissionAcceptance(rawInput);
      return;
    }
    if(isHeist){
      if(isHeistConfirmation){
        handleHeistConfirmation(rawInput);
      }else if(currentConfirmation){
        switch(currentConfirmation){
          case "guards-brief":
            handleGuardBriefConfirmation(rawInput);
            break;
          case "guards-mission":
            handleGuardMissionConfirmation(rawInput);
            break;
          case "guards-time":
            handleGuardTimeSelection(rawInput);
            break;
          case "guards-coordinates":
            handleGuardsCoordinates(rawInput);
            break;
          case "guards-on-location":
            handleGuardsOnLocation(rawInput);
            break;
          case "guards-hack":
            handleGuardsHack(rawInput);
            break;
          case "guards-bruteforce":
            handleGuardsBruteForce(rawInput);
            break;
          case "hardware-brief":
            handleHardwareBriefConfirmation(rawInput);
            break;
          case "hardware-mission":
            handleHardwareMissionConfirmation(rawInput);
            break;
          case "hardware-time":
            handleHardwareTimeSelection(rawInput);
            break;
          case "crates-brief":
            handleCratesBriefConfirmation(rawInput);
            break;
          case "crates-mission":
            handleCratesMissionConfirmation(rawInput);
            break;
          case "crates-time":
            handleCratesTimeSelection(rawInput);
            break;
        }
      }else{
        handleHeistCommand(rawInput);
      }
      return;
    }
    if(inputCommand==='hack'){
      // bail for no contract instnace
      if(!contractInstance){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command="No contract available, please reload Bootjack (this site)." />
          </div>
        ]);
        return;
      }
      // bail for no entered key
      console.log('hack argument',hackArg);
      if(!hackArg){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command="No key passed to minting function - you must enter 'hack <quantity> <hacking key>'" />
          </div>
        ]);
        return;
      }
      // bail for no mint number argument or out of range
      const mintCount = inputArg ? parseInt(inputArg) : 0;
      if(!inputArg || Number.isNaN(mintCount) || mintCount<1 || mintCount>10){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command={`${rawInput}: requires a numeric input in the range 1-10 e.g. 'hack 8 12345'`} />
          </div>
        ]);
        return;
      }
      // having passed those two checks, we're able to proceed with the mint
      console.log(`minting ${mintCount} tokens with password ${hackArg}`);
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <div>
            <p>Processing hack (mint request). Please follow the prompts on your screen...</p>
          </div>
        </div>
      ]);
      // do wallet stuff
      try{
        const successHash = await makeMint(mintCount, hackArg);
        const etherscanUrl = `https://${getNetworkPrefix()}etherscan.io/tx/${successHash}`;
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <div>
              <p>Hack successful. {mintCount} manifest{mintCount > 1 ? 's' : ''} secured!</p>
              <p>View your transaction here: <a href={etherscanUrl}>{successHash}</a></p>
            </div>
          </div>
        ]);
      }catch(err: any){
        // if it's got the reject message from the server, it's not really an error
        // but a hack rejection
        if(err.message.indexOf("INVALID_CODE/ip has been logged")>-1){
          setOutput([
            ...output,
            commandRecord,
            <div className="terminal-command-output">
              <ErrorMessage command={`INVALID_CODE ${hackArg}/ip has been logged`} />
            </div>
          ]);
        }else{
          console.error('error minting',err.message);
          setOutput([
            ...output,
            commandRecord,
            <div className="terminal-command-output">
              <ErrorMessage command={`Error in minting: ${JSON.stringify(err.message ?? err)}`} />
            </div>
          ]);
        }
      }
    } else if (inputCommand==='connect'){
      console.log('connect');
      // should take an argument of metamask or walletconnect
      // so bail if there is no argument or it's not metamask or walletconnect
      if(!inputArg || (inputArg!=="metamask" && inputArg!=="walletconnect")){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command={`${rawInput}: requires argument 'metamask' or 'walletconnect'`} />
          </div>
        ]);
        return;
      }
      let signerAddress: string|undefined;
      // let createdProvider: Web3Provider;
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>,
      ]);
      if(inputArg==="metamask"){
        const metaProvider = await connectEthers();
        signerAddress = await metaProvider?.getSigner().getAddress();
      }else{
        await connectWalletConnect();
      }
      if(inputArg==='walletconnect'){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <div>
              <p>Processing walletconnect</p>
            </div>
          </div>
        ]);
      }else if(signerAddress){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <div>
              <p>Connection established to {signerAddress}</p>
            </div>
          </div>,
        ]);
      }else{
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command="Failed to establish connection to signer address. Please ensure that you approve the wallet connection request." />
          </div>
        ]);
      }
    }else if(inputCommand==='heist'){
      props.setIsHeist(true);
    }else if(inputCommand==='play soundtrack'){
      const audioElement = new Audio('/Deep_State_-_Vans_in_Japan.mp3');
      audioElement.play();
      setAudioElement(audioElement);
      // then add as usual
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>
      ]);
    }else if (inputCommand==='stop soundtrack'){
      if(!audioElement){
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">Audio not playing</div>
        ]);
        return;
      }
      audioElement?.pause();
      const newAudio = {...audioElement, currentTime: 0};
      setAudioElement(newAudio);
      // terminal update
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>
      ]);
    }else if (!isValidCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <ErrorMessage command={inputCommand} />
        </div>,
      ]);
    } else if (isEchoCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>,
      ]);
    } else if (isUtilityCommand(inputCommand)) {
      switch (inputCommand) {
        case "clear": {
          setOutput([]);
          break;
        }
      }
    }
  };

  const makeMint = async (mintQuantity: number, hackArg: string) => {
    // console.log('do the mint of tokens x',mintQuantity);
    // should have provider, but check
    if(!provider){
      console.error('no provider');
      throw new Error("No ETH network provider");
    }
    if(!contractInstance){
      console.error('no contract instance');
      throw new Error("No contract instance available, please reload");
    }
    const signer = await provider.getSigner().getAddress();
    // NOTE statically set price
    const etherValue = mintQuantity * MINT_COST_ETH;
    const transactionOptions = { value: ethers.utils.parseEther(etherValue.toString())};
    const localSigner = provider.getSigner(signer);
    // console.log('make mint with',contractInstance,localSigner,mintQuantity,transactionOptions);
    const tx = await contractInstance.connect(localSigner).claim(mintQuantity, hackArg, transactionOptions);
    await tx.wait();
    return tx.hash;
  }

  const getHistory = (direction: "up" | "down") => {
    let updatedIndex;
    if (direction === "up") {
      updatedIndex = historyIndex === 0 ? 0 : historyIndex - 1;
    } else {
      updatedIndex =
        historyIndex === history.length ? history.length : historyIndex + 1;
    }
    setHistoryIndex(updatedIndex);
    return updatedIndex === history.length ? "" : history[updatedIndex];
  };

  const getAutocomplete = (input: string) => {
    const matchingCommands = allCommands.filter((c) => c.startsWith(input));
    if (matchingCommands.length === 1) {
      return matchingCommands[0];
    } else {
      const commandRecord = (
        <div
          ref={(el) => (scrollRef.current = el)}
          className="terminal-command-record"
        >
          <span className="terminal-prompt">{terminalPrompt}</span>{" "}
          <span>{input}</span>
        </div>
      );
      setOutput([...output, commandRecord, matchingCommands.join("    ")]);
      return input;
    }
  };

  const focusOnInput = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Tab") {
      // Prevent tab from moving focus
      event.preventDefault();
    }
    if(currentConfirmation==="guards-bruteforce" && event.key=== "1"){
      // event.preventDefault();
      setHackCount(hackCount+1);
      if(hackCount===21){
        setHackCount(1);
        setOutput([
          ...output,
          getCommandRecord("1"),
          <div className="terminal-command-output">
            <p>good work, renegade. Drive through the gate and park in employee parking. ACT NATURAL. If you have your chip gear on, then no one should suspect a thing.</p>
            <p>OBJECTIVE COMPLETE</p>
          </div>
        ]);
        setCurrentConfirmation(undefined);
        setObjectiveProgress({
          ...objectiveProgress,
          guards: objectiveProgress.guards+20
        });
      }
    }
    inputRef.current?.focus();
  };

  return (
    <div className="terminal-container" tabIndex={-1} onKeyDown={focusOnInput}>
      <div className="terminal-content">
        {banner && <Banner banner={banner} />}
        {welcomeMessage && (
          <WelcomeMessage message={welcomeMessage} inputRef={inputRef} />
        )}
        <TerminalOutput outputs={output} />
        <InputArea
          setOutput={setOutput}
          processCommand={processCommand}
          getHistory={getHistory}
          getAutocomplete={getAutocomplete}
          inputRef={inputRef}
          terminalPrompt={terminalPrompt}
        />
      </div>
    </div>
  );
};

export default Terminal;
