import React from "react";

type ErrorMessageProps = {
  command: string;
  heistError?: boolean
};
const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <div className="terminal-error-group">
      <span className="terminal-error">
        {`command error: ${props.command}.`}
      </span>
      { !props.heistError && <span>{`Type 'help' to view a list of available commands`}</span>}
    </div>
  );
};

export default ErrorMessage;