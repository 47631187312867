import { ProgressCategory, Story } from "./story-types";

const THATS_A_SHAME = "That’s a shame, runner. We thought we could depend on you, but it looks like our assumptions were wrong. Ping me if you change your mind… but I can’t guarantee you’ll get a response. Stay vigilant, runner. Somnites are lurking everywhere";

const commonSteps = {
  headIn: {
    text: "Head to the hideout and download your instructions from maraj [y/n]",
    responses: [
      { value: 'y', action: 'advance' },
      { value: 'n', action: 'twitter' }
    ]
  },
  greeting: {
    text: "Welcome, Runner. I see you've been doing some bootjacking. Find anything interesting? [y/n]",
    responses: [
      { value: 'y', action: 'advance' },
      { value: 'n', action: 'fail', text: THATS_A_SHAME }
    ]
  },
  chooseMethod: {
    text: "Okay I know where I'm going, but I need a strategy. I'll be rolling solo, so I can either use my cunning wit and COSPLAY A SOMNITE, or exercise brute force and pull off a classic SMASH AND GRAB.",
    responses: [
      { value: 'cosplay a somnite', action: 'namedStep: cosplay a somnite' },
      { value: 'smash and grab', action: 'namedStep: smash and grab' }
    ]
  },
  'smash and grab': {
    text: [
      "I’ll wait til the cover of darkness and then maneuver into the location to scope it out.",
      "SMASH...",
      "GRAB...",
      "RUN...",
      "Oh, shit. The Somnites are on my tail.",
      "[DUCK AND HIDE / KEEP RUNNING]"
    ],
    responses: [
      { value: 'duck and hide', action: 'namedStep: decision' }, // if you use this, you have to have this in your story
      { value: 'keep running', action: 'fail' }
    ]
  },
}

export const BashBunny: Story = {
  title: 'Bash Bunny',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about BASH BUNNIES in the shipping manifests you bootjacked?”",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Very good. Do what you have to do to get your hands on a bunny. I really don’t need to know the details. This hardware is essential to the success of our mission, runner. We’re counting on you. Be careful out there. There’s Somnites lurking everywhere.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': commonSteps['smash and grab'],
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This BASH BUNNY could be very useful to me… and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail', text: "You take a grim satisfaction in your pilfered tool, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters. ",
        "The BASH BUNNY is in sight",
        "/enact cloak",
        "Security disabled...",
        "Pending authorization…",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This BASH BUNNY could be very valuable on the black market… and who knows if the Renegades will even be able to pull off this crazy heist anyway…"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail', text: "You take a grim satisfaction in your pilfered tool, knowing you've betrayed Maraj's trust." }
      ]
    },
    'coordinates': {
      text: [
        "Maraj: “You’ll need to be well disguised to get past the Somnites at the depot. We’ve got some chip company gear that was lifted by our fellow Renegades. Put on this Doritos Windbreaker and no one will ever be the wiser. Hopefully”",
        "“Be careful out there, runner. These are not your typical Somnites if they’re moving this kind of firepower. Get the coordinates from The Hideout pin board. Once you get there, ping me on your Somcom and await further instruction. If anything seems off once you get there, then GET THE HELL OUT OF THERE.”",
        "Enter coordinates…"
      ],
      responses: [
        { value: "[651a 921b]", action: 'namedStep: transporting' },
        { value: '*', action: 'namedStep: retrycoordinates' }
      ]
    },
    'retrycoordinates': {
      text: "Incorrect. Try again. Format [a b]",
      responses: [
        { value: "[651a 921b]", action: "namedStep: securitygate" },
        { value: "*", action: "namedStep: retrycoordinates" }
      ]
    },
    'securitygate': {
      text: [
        "Arrived location [651a 921b]",
        "[PING MARAJ / DRIVE RIGHT IN]"
      ],
      responses: [
        { value: "ping maraj", action: "namedStep: enterdepot" },
        { value: "drive right in", action: 'fail' }
      ]
    },
    'enterdepot': {
      text: [
        "Maraj: “Okay runner, when you get to the gate use this Key Card to pass security, park in employee parking and act casual. Walk in the front door, give them an alias, and tell them you are there to see Cosmo. If they ask for a pin code, it’s 5631. I’ll be running a jammer on the network to give you cover. Deploy the CODE BOMB by entering 20 1’s on the mainframe console.”",
        "[GET THE HELL OUT / GO IN THE DEPOT]"
      ],
      responses: [
        { value: "get the hell out", action: 'fail' },
        { value: "go in the depot", action: 'namedStep: security_one' }
      ]
    },
    'security_one': {
      text: [
        "Security: “Name and registration please”",
        "[enter a name]"
      ],
      responses: [
        { value: '*', action: 'namedStep: security_two' },
      ]
    },
    'security_two': {
      text: "Registration:",
      responses: [
        { value: "5631", action: "namedStep: security_three" },
        { value: "*", action: 'fail' }
      ]
    },
    'security_three': {
      text: "Security: “Okay… who did you say you were here to see again?”",
      responses: [
        { value: "cosmo", action: 'namedStep: meetcosmo' },
        { value: "*", action: 'fail' }
      ]
    },
    'meetcosmo': {
      text: [
        "Cosmo: “HELLO… er… Sir! We’ve been expecting you, of course.”",
        "“You’re here to perform the… server maintenance, correct?”",
        "“Yes, yes. The server room is right this way, please, follow me.”",
        "INTERIOR, SERVER ROOM",
        "Okay, I need to plant the BASH BUNNY and get the hell out. Am I sure this guy is trustworthy? He sure is acting strange.",
        "[INSTALL HARDWARE / GET THE HELL OUT]"
      ],
      responses: [
        { value: 'install hardware', action: 'namedStep: installbunny' },
        { value: 'get the hell out', action: 'fail' }
      ]
    },
    'installbunny': {
      text: [
        "Somcom rings...",
        "Maraj: “Now that you're inside, go straight to the mainframe, install the BASH BUNNY and get out of there before anyone sees you. The cloak protocol will only last for 2 minutes, tops. So you need to ACT FAST.”",
        "MAINFRAME CONSOLE:"
      ],
      responses: [
        { 
          value: '11111111111111111111', 
          action: 'completion', 
          text: [
            "Deploying Code Bomb…",
            "Loading...",
            "COMPLETE",
            "Exiting swiftly...",
            "Done."
          ]
        }
      ]
    }
  },
  completion: {
    text: "That certainly got the Somnites' attention. You'll want to lay low, just in case, but hopefully Maraj will be in touch soon.",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.HARDWARE, value: 5 }
  }
}
export const CodeBomb: Story = {
  title: 'Code Bomb',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about CODE BOMBS in the shipping manifests you bootjacked?”",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Very good. Do what you have to do to get your hands on a code bomb. I really don’t need to know the details. This hardware is essential to the success of our mission, runner. We’re counting on you. Be careful out there. There’s Somnites lurking everywhere.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    {
      text: "Okay I know where it is, but I need a strategy. The Somnites are dumb but they’re not stupid. I’ll need to execute an effective CLOAK PROTOCOL or run a QUICK BOOTJACK to tap into the local network and download the CODE BOMB.",
      responses: [
        { value: 'quick bootjack', action: 'namedStep: bootjack' },
        { value: 'cloak protocol', action: 'namedStep: cloakprotocol' }
      ]
    }
  ],
  namedSteps: {
    'bootjack': {
      text: [
        "You need to enter the NETWORK ADDRESS to access this location. Check your runner contacts and the pin board in THE HIDEOUT.",
        "ENTER NETWORK ADDRESS:"
      ],
      responses: [
        { value: "53658745a2356ffz", action: 'namedStep: bootjack_one' },
        { value: "*", action: "fail" }
      ]
    },
    'bootjack_one': {
      text: [
        "Looks like I’m flying under the radar. Now where is this thing hiding…",
        "Searching…",
        "Searching…",
        "CODE BOMB LOCATED",
        "Download? [YES/NO]"
      ],
      responses: [
        { value: 'yes', action: 'namedStep: download' },
        { value: 'no', action: 'fail' }
      ]
    },
    'cloakprotocol': {
      text: [
        "/runcloak",
        "Executing",
        "Cloak enabled",
        "cloaking",
        "Oh, shit. Mega Mobile techs are zeroing in on my position.",
        "[DUCK AND HIDE / KEEP HACKING]"
      ],
      responses: [
        { value: 'duck and hide', action: 'fail' },
        { value: 'keep hacking', action: 'namedStep: download' }
      ]
    },
    'download': {
      text: [
        "Think I lost them. I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "Downloading the code bomb",
        "Download complete",
        "Hmm… This CODE BOMB could be very useful to me… and who knows if they’ll even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'completion' },
        { value: 'keep it', action: 'fail', text: "You pocket the CODE BOMB, knowing you've betrayed Maraj's trust" }
      ]
    }
  },
  completion: {
    text: "Maraj: “Excellent work, runner. Your efforts are not going unnoticed. Keep this up and you’ll be richly rewarded. Renegades take care of their own. A talented bootjacker like yourself is always in demand around here.”",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.HARDWARE, value: 5 }
  }
}
export const RaspberryPi3: Story = {
  title: 'Raspberry Pi 3',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about a RASPBERRY PI 3 in the shipping manifests you bootjacked?”",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Very good. Do what you have to do to get your hands on a Raspberry Pi 3. I really don’t need to know the details. This hardware is essential to the success of our mission, runner. We’re counting on you. Be careful out there. There’s Somnites lurking everywhere.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': commonSteps['smash and grab'],
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This RASPBERRY PI 3 could be very useful to me… and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail', text: "You take a grim satisfaction in your pilfered tool, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters.",
        "The RASPBERRY PI 3 is in sight. They’re falling for it.",
        "One last check… Yes.",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This RASPBERRY PI 3 could be very useful to me… and who knows if they’ll even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail' }
      ]
    },
    'coordinates': {
      text: [
        "Maraj: “You’ll need to be well disguised to get past the Somnites at the depot. We’ve got some chip company gear that was lifted by our fellow Renegades. Put on this Doritos Windbreaker and no one will ever be the wiser. Hopefully”",
        "“Be careful out there, runner. These are not your typical Somnites if they’re moving this kind of firepower. Get the coordinates from The Hideout pin board. Once you get there, ping me on your Somcom and await further instruction. If anything seems off once you get there, then GET THE HELL OUT OF THERE.”",
        "Enter coordinates…"
      ],
      responses: [
        { value: "[651a 921b]", action: 'namedStep: transporting' },
        { value: '*', action: 'namedStep: retrycoordinates' }
      ]
    },
    'retrycoordinates': {
      text: "Incorrect. Try again. Format [a b]",
      responses: [
        { value: "[651a 921b]", action: "namedStep: securitygate" },
        { value: "*", action: "namedStep: retrycoordinates" }
      ]
    },
    'securitygate': {
      text: [
        "Arrived location [651a 921b]",
        "[PING MARAJ / DRIVE RIGHT IN]"
      ],
      responses: [
        { value: "ping maraj", action: "namedStep: enterdepot" },
        { value: "drive right in", action: 'fail' }
      ]
    },
    'enterdepot': {
      text: [
        "Maraj: “Okay runner, when you get to the gate use this Key Card to pass security, park in employee parking and act casual. Walk in the front door, give them an alias, and tell them you are there to see Cosmo. If they ask for a pin code, it’s 5631. I’ll be running a jammer on the network to give you cover. Connect the RASPBERRY PI 3 to the LONG RANGE ANTENNA by entering 20 1’s on the mainframe console.”",
        "[GET THE HELL OUT / GO IN THE DEPOT]"
      ],
      responses: [
        { value: "get the hell out", action: 'fail' },
        { value: "go in the depot", action: 'namedStep: security_one' }
      ]
    },
    'security_one': {
      text: [
        "Security: “Name and registration please”",
        "[enter a name]"
      ],
      responses: [
        { value: '*', action: 'namedStep: security_two' },
      ]
    },
    'security_two': {
      text: "Registration:",
      responses: [
        { value: "5631", action: "namedStep: security_three" },
        { value: "*", action: 'fail' }
      ]
    },
    'security_three': {
      text: "Security: “Okay… who did you say you were here to see again?”",
      responses: [
        { value: "cosmo", action: 'namedStep: meetcosmo' },
        { value: "*", action: 'fail' }
      ]
    },
    'meetcosmo': {
      text: [
        "Cosmo: “HELLO… er… Sir! We’ve been expecting you, of course.”",
        "“You’re here to perform the… server maintenance, correct?”",
        "“Yes, yes. The server room is right this way, please, follow me.”",
        "INTERIOR, SERVER ROOM",
        "Okay, I need to plant the RASPBERRY PI 3 and get the hell out. These Somnites give me the creeps. Am I sure this guy is trustworthy? He sure is acting strange.",
        "[INSTALL HARDWARE / GET THE HELL OUT]"
      ],
      responses: [
        { value: 'install hardware', action: 'namedStep: installhardware' },
        { value: 'get the hell out', action: 'fail' }
      ]
    },
    'installhardware': {
      text: [
        "Somcom rings...",
        "Maraj: Now that you're inside, go straight to the mainframe room, inside there’s a ladder to the roof. Go to the roof and connect the RASPBERRY PI 3 the the LONG RANGE ANTENNA and get out of there before anyone sees you. Cosmo will watch the door for you, but don’t be seen. The cloak protocol will only last for 2 minutes, tops. So you need to ACT FAST.”",
        "CONNECT TO ANTENNA:"
      ],
      responses: [
        { 
          value: '11111111111111111111', 
          action: 'completion', 
          text: [
            "Antenna connected…",
            "Exiting swiftly...",
            "Done."
          ]
        }
      ]
    }
  },
  completion: {
    text: "",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.HARDWARE, value: 5 }
  }
}
export const LongRangeAntenna: Story = {
  title: 'Long Range Antenna',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about a LONG RANGE ANTENNA in the shipping manifests you bootjacked?”",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Very good. Do what you have to do to get your hands on a Long Range Antenna. I really don’t need to know the details. This hardware is essential to the success of our mission, runner. We’re counting on you. Be careful out there. There’s Somnites lurking everywhere.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'smash and grab': commonSteps['smash and grab'],
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This LONG RANGE ANTENNA could be very useful to me… and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail', text: "You take a grim satisfaction in your pilfered tool, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters.",
        "The LONG RANGE ANTENNA is in sight. They’re falling for it.",
        "One last check… Yes.",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This LONG RANGE ANTENNA could be very useful to me… and who knows if they’ll even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail' }
      ]
    },
    'coordinates': {
      text: [
        "Maraj: “You’ll need to be well disguised to get past the Somnites at the depot. We’ve got some chip company gear that was lifted by our fellow Renegades. Put on this Doritos Windbreaker and no one will ever be the wiser. Hopefully”",
        "“Be careful out there, runner. These are not your typical Somnites if they’re moving this kind of firepower. Get the coordinates from The Hideout pin board. Once you get there, ping me on your Somcom and await further instruction. If anything seems off once you get there, then GET THE HELL OUT OF THERE.”",
        "Enter coordinates…"
      ],
      responses: [
        { value: "[651a 921b]", action: 'namedStep: transporting' },
        { value: '*', action: 'namedStep: retrycoordinates' }
      ]
    },
    'retrycoordinates': {
      text: "Incorrect. Try again. Format [a b]",
      responses: [
        { value: "[651a 921b]", action: "namedStep: securitygate" },
        { value: "*", action: "namedStep: retrycoordinates" }
      ]
    },
    'securitygate': {
      text: [
        "Arrived location [651a 921b]",
        "[PING MARAJ / DRIVE RIGHT IN]"
      ],
      responses: [
        { value: "ping maraj", action: "namedStep: enterdepot" },
        { value: "drive right in", action: 'fail' }
      ]
    },
    'enterdepot': {
      text: [
        "Maraj: “Okay runner, when you get to the gate use this Key Card to pass security, park in employee parking and act casual. Walk in the front door, give them an alias, and tell them you are there to see Cosmo. If they ask for a pin code, it’s 5631. I’ll be running a jammer on the network to give you cover. Sync the LONG RANGE ANTENNA to an installed RASPBERRY PI 3 by entering 20 1’s on the mainframe console.”",
        "[GET THE HELL OUT / GO IN THE DEPOT]"
      ],
      responses: [
        { value: "get the hell out", action: 'fail' },
        { value: "go in the depot", action: 'namedStep: security_one' }
      ]
    },
    'security_one': {
      text: [
        "Security: “Name and registration please”",
        "[enter a name]"
      ],
      responses: [
        { value: '*', action: 'namedStep: security_two' },
      ]
    },
    'security_two': {
      text: "Registration:",
      responses: [
        { value: "5631", action: "namedStep: security_three" },
        { value: "*", action: 'fail' }
      ]
    },
    'security_three': {
      text: "Security: “Okay… who did you say you were here to see again?”",
      responses: [
        { value: "cosmo", action: 'namedStep: meetcosmo' },
        { value: "*", action: 'fail' }
      ]
    },
    'meetcosmo': {
      text: [
        "Cosmo: “HELLO… er… Sir! We’ve been expecting you, of course.”",
        "“You’re here to perform the… server maintenance, correct?”",
        "“Yes, yes. The server room is right this way, please, follow me.”",
        "INTERIOR, SERVER ROOM",
        "Okay, I need to plant the LONG RANGE ANTENNA and get the hell out. These Somnites give me the creeps. Am I sure this guy is trustworthy? He sure is acting strange.",
        "[INSTALL HARDWARE / GET THE HELL OUT]"
      ],
      responses: [
        { value: 'install hardware', action: 'namedStep: installhardware' },
        { value: 'get the hell out', action: 'fail' }
      ]
    },
    'installhardware': {
      text: [
        "Somcom rings...",
        "Maraj: Now that you're inside, go straight to the mainframe room, inside there’s a ladder to the roof. Go to the roof and connect the RASPBERRY PI 3 the the LONG RANGE ANTENNA and get out of there before anyone sees you. Cosmo will watch the door for you, but don’t be seen. The cloak protocol will only last for 2 minutes, tops. So you need to ACT FAST.”",
        "CONNECT TO RASPBERRY PI 3:"
      ],
      responses: [
        { 
          value: '11111111111111111111', 
          action: 'completion', 
          text: [
            "Antenna connected…",
            "Exiting swiftly...",
            "Done."
          ]
        }
      ]
    }
  },
  completion: {
    text: "",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.HARDWARE, value: 5 }
  }
}