import { ProgressCategory, Story } from "./story-types";

export const BootjackerStory: Story = {
  steps: [
    {
      text: "Head to the hideout and download your instructions from maraj [y/n]",
      responses: [
        { value: 'y', action: 'advance' },
        { value: 'n', action: 'twitter' }
      ]
    },
    {
      text: "Welcome, Runner. We could use some auxiliary support during the heist to monitor network coms and protect the cloak protocol. Are you up for aiding in the heist? [y/n]",
      responses: [
        { value: 'y', action: 'advance' },
        { 
          value: 'n', 
          action: 'fail', 
          text: "That’s a shame, runner. We thought we could depend on you, but it looks like our assumptions were wrong. Ping me if you change your mind… but I can’t guarantee you’ll get a response. Stay vigilant, runner. Somnites are lurking everywhere"
        }
      ]
    },
    {
      text: `Excellent. Thanks for your help, Runner. We’re going to be sending a small team of Renegade operatives disguised as Somnites to take control of the Chip Factory. Once we have control, we’ll be able to drive in a truck and unload the crates, no questions asked. First, we need to establish a local network connection and install the hacking equipment to reroute coms to THE HIDEOUT.

Your bootjacking skills are needed to RUN THE CLOAK PROTOCOL and LOGJAM LOCAL SECURITY`,
      responses: [
        { 
          value: 'run the cloak protocol', 
          action: 'cloak protocol',
          text: "While our fellow Renegades are preparing for the heist, we’ll need to obscure their SomCom activity so Maraj can run coms to operatives in the field. Whenever a Mega Mobile security scan appears on the network run /cloak and send the coms to a spoofed network address. Whenever Maraj’s alias appears, route them to the correct network address [54df25g9jk2]."
        },
        { 
          value: 'logjam local security', 
          action: 'logjam',
          text: "The Renegade operatives will need help from bootjackers in THE HIDEOUT as they are traversing security checkpoints at the depot. You’ll need to hack into the Mega Mobile network and LOGJAM any RENEGADE calls you see. Make sure to allow other aliases by to avoid raising suspicions."
        }
      ]
    }
  ],
  completion: {
    text: "Good job, Runner. It looks like our efforts are working and the operatives are moving into place. As they complete their objectives, we’ll know more about the situation on the ground. You’ll hear from us soon. Remember to check your coms and stay vigilant. There’s Somnites lurking everywhere.",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.GUARDS, value: 5 }
  }
};

export const getCloakPrompt = () => Math.random()>0.5 ? 'MARAJ' : 'MEGA MOBILE';

export const getLogjamPrompt = () => Math.random()>0.3 ? 'RENEGADE' : getRandomMegamobileName();

const getRandomMegamobileName = (): string => {
  const aliasList = [
    'TUPPERWARE', 'BIGCAP', 'FRANKENSTEIN', 'CHIPKING', 'DONUT', 'MADDOG',
    'LOOSE UNIT', 'PEPE', '0XFORD', 'ZETA', 'CORVID', 'ALOYSIUS'
  ];
  return aliasList[Math.floor(Math.random()*10+1)];
}