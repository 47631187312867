import { ProgressCategory, Story } from "./story-types";

const THATS_A_SHAME = "That’s a shame, runner. We thought we could depend on you, but it looks like our assumptions were wrong. Ping me if you change your mind… but I can’t guarantee you’ll get a response. Stay vigilant, runner. Somnites are lurking everywhere";

const commonSteps = {
  headIn: {
    text: "Head to the hideout and download your instructions from maraj [y/n]",
    responses: [
      { value: 'y', action: 'advance' },
      { value: 'n', action: 'twitter' }
    ]
  },
  greeting: {
    text: "Welcome, Runner. I see you've been doing some bootjacking. Find anything interesting? [y/n]",
    responses: [
      { value: 'y', action: 'advance' },
      { value: 'n', action: 'fail', text: THATS_A_SHAME }
    ]
  },
  chooseMethod: {
    text: "Okay I know where I'm going, but I need a strategy. I'll be rolling solo, so I can either use my cunning wit and COSPLAY A SOMNITE, or exercise brute force and pull off a classic SMASH AND GRAB.",
    responses: [
      { value: 'cosplay a somnite', action: 'namedStep: cosplay a somnite' },
      { value: 'smash and grab', action: 'namedStep: smash and grab' }
    ]
  },
  'smash and grab': {
    text: [
      "I’ll wait til the cover of darkness and then maneuver into the location to scope it out.",
      "SMASH...",
      "GRAB...",
      "RUN...",
      "Oh, shit. The Somnites are on my tail.",
      "[DUCK AND HIDE / KEEP RUNNING]"
    ],
    responses: [
      { value: 'duck and hide', action: 'namedStep: decision' }, // if you use this, you have to have this in your story
      { value: 'keep running', action: 'fail' }
    ]
  },
}

export const DoritosDust: Story = {
  title: 'Doritos Dust',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: [
        "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about DORITOS DUST?”",
        "[y/n]" 
      ],
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Very good. We’re going to need some DORITOS DUST for the CHIP FACTORY heist. It’d be a huge help to our cause if you can cop some dust and bring it back to THE HIDEOUT. We’ll be a team of Renegade operatives into the Chip Factory to UNLOAD THE CRATES and heist the loot. They’ll need some DORITOS DUST to distract the guards. Be careful out there, Renegade. There’s Somnites lurking everywhere. Also, a word of advice, sometimes the best strategy is to just lay low.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    commonSteps.chooseMethod
  ],
  namedSteps: {
    'decision': {
      text: [
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This DORITOS DUST could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this stuff... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail', text: "You take a grim satisfaction in your pilfered tool, knowing you've betrayed Maraj's trust." }
      ]
    },
    'cosplay a somnite': {
      text: [
        "You’re feeling confident with the shipping manifest in hand. These stupid Somnites will never suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters. ",
        "The DORITOS DUST is in sight",
        "/enact cloak",
        "Security disabled...",
        "Pending authorization…",
        "Transaction complete.",
        "I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?",
        "This DORITOS DUST could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this stuff... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[TURN IT IN / KEEP IT]"
      ],
      responses: [
        { value: 'turn it in', action: 'namedStep: coordinates' },
        { value: 'keep it', action: 'fail', text: "You take a grim satisfaction in your pilfered tool, knowing you've betrayed Maraj's trust." }
      ]
    },
    'smash and grab': commonSteps['smash and grab'],
    'coordinates': {
      text: [
        "Maraj: “Excellent work, Renegade. The heist team is assembling at the rendezvous point in the Industrial Sector. Take the DUST down there and look for a Doritos Truck. Be careful out there. These are not your typical Somnites if they’re moving this kind of firepower. Get the coordinates from The Hideout pin board. Once you get there, ping me on your Somcom.”",
        "Enter coordinates…"
      ],
      responses: [
        { value: "[651a 921b]", action: 'namedStep: sketchTruck' },
        { value: '*', action: 'namedStep: retrycoordinates' }
      ]
    },
    'retrycoordinates': {
      text: "Incorrect. Try again. Format [a b]",
      responses: [
        { value: "[651a 921b]", action: "namedStep: sketchTruck" },
        { value: "*", action: "namedStep: retrycoordinates" }
      ]
    },
    'sketchTruck': {
      text: [
        "Transporting...",
        "Arrived location [651a 921b]",
        "Doritos truck is in sight.",
        "This looks sketchy as hell. Am I sure these Doritos guys aren't really Somnite goons? I have a bad feeling about this...",
        "[APPROACH THE TRUCK / DUCK AND RUN]"
      ],
      responses: [
        { value: 'approach the truck', action: 'namedStep: inquiry' },
        { value: 'duck and run', action: 'fail' }
      ]
    },
    'inquiry': {
      text: [
        "APPROACH THE TRUCK",
        "Anon Driver: “You got the stuff?”",
        "[yes / no]"
      ],
      responses: [
        { value: 'yes', action: 'completion', text: "Yeah. Got it right here." },
        { value: 'no', action: 'fail' }
      ]
    }
  },
  completion: {
    text: "Anon Driver: “Nice. Thanks for your help runner. We’ll see you back at The Hideout. Stay vigilant, Somnites are lurking everywhere”",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.GUARDS, value: 5 }
  }
}
export const DoritosHqKeyCard: Story = {
  title: 'Doritos HQ Key Card',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Maraj: “Excellent. We need all the help we can get with this one. So, I see you were able to bootjack a shipping manifest. Did you see anything about Doritos HQ Key Card?”",
      responses: [
        { value: 'y', action: 'check coordinates', text: "Maraj: “Here’s what you need to do: go cop the card. Ping me on your SomCom when you have it and I’ll give you further instructions.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    {
      text: [
        "Sheesh… I can’t believe I pulled that off. Never thought I’d find myself impersonating a Somnite. Still can’t believe they’re willing to hand over a DORITOS HQ KEY CARD to anyone with a shipping manifest. Mega Mobile really has them fooled into believing their network can’t be hacked.",
        "This DORITOS HQ KEY CARD could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[KEEP THE CARD / PING MARAJ]"
      ],
      responses: [
        { value: 'keep the card', action: 'fail', text: "Damnit. I hate bailing on my fellow Renegades, but this Key Card is too valuable to just hand over. Plus, who knows if they’ll even pull off this crazy heist anyway." },
        { value: 'ping maraj', action: 'completion' }
      ]
    }
  ],
  completion: {
    text: "Maraj: “Good work, runner. We’re going to use this card to steal merch from Doritos HQ so our fellow Renegades can execute the Trojan Horse. Drop it off at THE HIDEOUT. BE careful out there, Runner. Somnites are lurking everywhere.”",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.GUARDS, value: 5 }
  }
}
export const DoritosPolo: Story = {
  title: 'Doritos Polo',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about Doritos Polos  in the shipping manifests you bootjacked?””",
      responses: [
        { value: 'y', action: 'advance' },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    {
      text: [
        "Maraj: “Excellent. We’re going to need those DORITOS POLOS so our operatives can infiltrate the CHIP FACTORY. Here’s a Key Card to Doritos Downtown Mega City HQ building. You’ll need to successfully cosplay as a Somnite to pull this off. Once you're past security, head to the 17th floor. Show your manifest to the clerk at the shipping and receiving desk and they should hand you a box of DORITOS POLOS… if you don’t do anything to raise their suspicions.”",
        "[COP THE POLO / GET THE HELL OUT]"
      ],
      responses: [
        { value: 'cop the polo', action: 'advance' },
        { value: 'get the hell out', action: 'fail' }
      ]
    },
    {
      text: [
        "ARRIVE DORITOS HQ",
        "Shit.. this seems like a lot of security for an office building. What the hell are they up to here?",
        "SECURITY: Scan Your Badge",
        "ERROR",
        "Shit. Shit. Shit.",
        "Security Guard: “Sir, can you show me your badge please?”",
        "…examining your badge…",
        "Security Guard: “You got to hold it just right. These things are finicky as hell. Have a good day!”",
        "SCAN",
        "SUCCESS",
        "Elevator",
        "What floor am I supposed to go to?"
      ],
      responses: [
        { value: '17', action: 'advance' },
        { value: '*', action: 'fail' }
      ]
    },
    { 
      text: [
        "ARRIVE 17th FLOOR",
        "Shipping Clerk: “Hello! How can I help you? Do you have a shipping manifest for the merchandise you’re looking for?”",
        "You present your shipping manifest, entitling you to a shipment of DORITO POLOS",
        "Shipping Clerk: “Hm… it doesn’t seem like we have that shipment here. Are you sure they were supposed to come to this location?”",
        "[Yes / No]"
      ],
      responses: [
        { value: 'yes', action: 'advance' },
        { value: 'no', action: 'fail' }
      ]
    },
    {
      text: [
        "“Can’t you help a coworker out? My boss is going to be pissed”",
        "Shipping Clerk: “Okay, let me take another look…”",
        "Clerk leaves and goes to the back",
        "“Well, looks like we do have a box of Polos here. Here you go, take it. This shit just sits around anyway. Have a good one!”",
        "Sheesh… I can’t believe I pulled that off. Never thought I’d find myself impersonating a Somnite. Still can’t believe they’re willing to hand over these Doritos Polos to anyone with a shipping manifest. Mega Mobile really has them fooled themselves into believing their network can’t be hacked.",
        "These DORITOS POLOS could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for these things... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[KEEP THE POLOS / TAKE POLOS TO MARAJ]"
      ],
      responses: [
        { value: 'keep the polos', action: 'fail', text: "Damnit. I hate bailing on my fellow Renegades, but these Polos are too valuable to just just hand over. Plus, who knows if they’ll even pull off this crazy heist anyway." },
        { value: 'take polos to maraj', action: 'completion' }
      ]
    }
  ],
  completion: {
    text: "Maraj: “Good work, runner. We’re going to use these Doritos Polos to impersonate chip company employees and take over the shipping depot. Keep an eye on your coms. We can always have jobs for runners like you.”",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.GUARDS, value: 5 }
  }
}
export const DoritoWindbreaker: Story = {
  title: 'Dorito Windbreaker',
  steps: [
    commonSteps.headIn,
    commonSteps.greeting,
    {
      text: [
        "Maraj: “Excellent. We need all the help we can get with this one. Did you see anything about DORITOS WINDBREAKERs in the shipping manifests you bootjacked?””",
        "Are you in? [Y/N]"
      ],
      responses: [
        { value: 'y', action: 'advance', text: "Maraj: “Excellent. We’re going to need those DORITOS WINDBREAKERS so our operatives can infiltrate the CHIP FACTORY. Here’s a Key Card to Doritos Downtown Mega City HQ building. You’ll need to successfully cosplay as a Somnite to pull this off. Once you're past security, head to the 17th floor. Show your manifest to the clerk at the shipping and receiving desk and they should hand you a box of DORITOS WINDBREAKERS… if you don’t do anything to raise their suspicions.”" },
        { value: 'n', action: 'fail', text: "Damn. That's a shame. Come back when you're feeling helpful." }
      ]
    },
    {
      text: [
        "ARRIVE DORITOS HQ",
        "Shit.. this seems like a lot of security for an office building. What the hell are they up to here?",
        "SECURITY: Scan Your Badge",
        "ERROR",
        "Shit. Shit. Shit.",
        "Security Guard: “Sir, can you show me your badge please?”",
        "…examining your badge…",
        "Security Guard: “You got to hold it just right. These things are finicky as hell. Have a good day!”",
        "SCAN",
        "SUCCESS",
        "Elevator",
        "What floor am I supposed to go to?"
      ],
      responses: [
        { value: '17', action: 'advance' },
        { value: '*', action: 'fail' }
      ]
    },
    { 
      text: [
        "ARRIVE 17th FLOOR",
        "Shipping Clerk: “Hello! How can I help you? Do you have a shipping manifest for the merchandise you’re looking for?”",
        "You present your shipping manifest, entitling you to a shipment of DORITO WINDBREAKERS",
        "Shipping Clerk: “Hm… it doesn’t seem like we have that shipment here. Are you sure they were supposed to come to this location?”",
        "[Yes / No]"
      ],
      responses: [
        { value: 'yes', action: 'advance' },
        { value: 'no', action: 'fail' }
      ]
    },
    {
      text: [
        "“Can’t you help a coworker out? My boss is going to be pissed”",
        "Shipping Clerk: “Okay, let me take another look…”",
        "Clerk leaves and goes to the back",
        "“Well, looks like we do have a box of windbreakers here. Here you go, take it. This shit just sits around anyway. Have a good one!”",
        "Sheesh… I can’t believe I pulled that off. Never thought I’d find myself impersonating a Somnite. Still can’t believe they’re willing to hand over these Doritos Windbreakers to anyone with a shipping manifest. Mega Mobile really has them fooled themselves into believing their network can’t be hacked.",
        "These DORITOS WINDBREAKERS could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for these things... and who knows if the Renegades will even be able to pull off this crazy heist anyway…",
        "[KEEP THE WINDBREAKERS / TAKE WINDBREAKERS TO MARAJ]"
      ],
      responses: [
        { value: 'keep the windbreakers', action: 'fail', text: "Damnit. I hate bailing on my fellow Renegades, but these windbreakers are too valuable to just just hand over. Plus, who knows if they’ll even pull off this crazy heist anyway." },
        { value: 'take windbreakers to maraj', action: 'completion' }
      ]
    }
  ],
  completion: {
    text: "Maraj: “Good work, runner. We’re going to use these Doritos WINDBREAKERs to impersonate chip company employees and take over the shipping depot. Keep an eye on your coms. We can always have jobs for runners like you.”",
    poapData: "Collect your rewards in The Hideout. Here is the password: [rr237dzq].",
    progress: { section: ProgressCategory.GUARDS, value: 5 }
  }
}