import React, { useState, useEffect, ReactElement, useRef } from "react";
import Banner from "./Banner";
import TerminalOutput from "./TerminalOutput";
import InputArea from "./InputArea";
import ErrorMessage from "./ErrorMessage";
import WelcomeMessage from "./WelcomeMessage";
import ObjectiveStatus from "./ObjectiveStatus";
import { Web3Provider } from '@ethersproject/providers';
import { getAttributesFromTokenLists, getContractAddress } from "../ContractUtil";
import { checkCoordinates, getMissionStory, handleStoryInput } from "../stories/story-util";
import { getCloakPrompt, getLogjamPrompt } from "../stories/bootjacker";
import { Story, StoryResponse } from '../stories/story-types';
import Moralis from 'moralis';

declare let window: any;

type HeistProps = {
  welcomeMessage: string;
  banner: string;
  setIsHeist: (input: boolean) => void;
  provider: Web3Provider|undefined;
}
const HeistTerminal = (props: HeistProps) => {
  const attributeRef = useRef<string[]>([]);
  const {welcomeMessage, banner, setIsHeist, provider} = props;
  const terminalPrompt = "heist>";
  const [output, setOutput] = useState<(string | JSX.Element)[]>([]);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(3);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const [isHeistConfirmation, setIsHeistConfirmation] = useState(false);
  const [isMissionSelection, setIsMissionSelection] = useState(false);
  const [activeStory, setActiveStory] = useState<Story>();
  const [storyIndex, setStoryIndex] = useState(0);
  const [specialState, setSpecialState] = useState<string|null>(null);
  const [localSuccesses, setLocalSuccesses] = useState(0);
  const [localFailures, setLocalFailures] = useState(0);
  const [specialPrompt, setSpecialPrompt] = useState<string|null>(null);

  // nft tracking
  const [manifestList, setManifestList] = useState<any[]>();
  const [runnerList, setRunnerList] = useState<any[]>();

  const [objectiveProgress, setObjectiveProgress] = useState({guards: 0, hardware: 0, crates: 0});
  const [isMissionAcceptance, setIsMissionAcceptance] = useState(false);
  const [isNamePrompt, setIsNamePrompt] = useState(false);
  const [username, setUsername] = useState('');
  const [activeNamedStep, setActiveNamedStep] = useState<string>();

  useEffect(() => {
    setIsMissionAcceptance(true);
    setOutput([
      ...output,
      'heist',
      <div className="terminal-command-output">
        <p>Runners,</p>
        <p>Renegades in The Hideout have been tracking somnite coms related to the bootjack hack. The Somnites are not happy with us meddling with their plans. However, our efforts aren’t stopping them from moving forward with their nefarious scheme.</p>
        <p>Based on encrypted comms we intercepted on the Mega Mobile network, it appears that some of the weapons from compromised manifests are being diverted to temporary depots while they decide what to do next. </p>
        <p>We’ve been able to geolocate com activity that is converging on a warehouse building in the industrial sector that looks to be a chip factory that’s been converted into a secret weapons depot.</p>
        <p>Renegade operatives have traveled to the site and confirmed the location and placed a tag for fellow renegades to be able to identify the building.</p>
        <p>Here is your mission, should you choose to accept it. Infiltrate the weapons depot, install hacking hardware, and heist the incoming arms.</p>
        <p>Stay vigilant runners. Somnites are lurking everywhere.</p>
        <p>&nbsp;&nbsp;- Maraj</p>
        <p>Accept Mission [y/n]?</p>
      </div>
    ]);

    Moralis.start({ serverUrl: process.env.REACT_APP_MORALIS_SERVER, appId: process.env.REACT_APP_MORALIS_ID });
    // just to make sure we're being real clear
    console.log(process.env.REACT_APP_NETWORK);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const scrollLastCommandTop = () => {
    scrollRef.current?.scrollIntoView();
  };



  const renderStepText = (stepText: undefined | string | string[]) => {
    if(stepText===undefined) return <></>;
    if(typeof stepText==='string'){
      return <dd>{stepText}</dd>;
    }else{
      return (
        <>
          { stepText.map((line, index) => <dd key={`steptext${index}`}>{line}</dd>) }
        </>
      );
    }
  }

  useEffect(scrollLastCommandTop, [output]);

  const echoCommands = [
    "mission briefing",
    "help"
  ] as const;
  type EchoCommand = typeof echoCommands[number];
  const utilityCommands = ["clear"] as const;
  type UtilityCommand = typeof utilityCommands[number];
  const heistCommands = [
    "connect wallet",
    "abort",
    "start heist",
    "bypass guards",
    "day",
    "night",
    "plant hardware",
    "unload crates"
  ] as const;
  type HeistCommand = typeof heistCommands[number];
  const allCommands = [...echoCommands, ...utilityCommands, ...heistCommands] as const;
  type Command = typeof allCommands[number];

  function isEchoCommand(arg: string): arg is EchoCommand {
    return (echoCommands as ReadonlyArray<string>).includes(arg);
  }

  function isUtilityCommand(arg: string): arg is UtilityCommand {
    return (utilityCommands as ReadonlyArray<string>).includes(arg);
  }

  function isHeistCommand(arg: string): arg is HeistCommand {
    return (heistCommands as ReadonlyArray<string>).includes(arg);
  }

  function isValidCommand(arg: string): arg is Command {
    return isEchoCommand(arg) || isUtilityCommand(arg) || isHeistCommand(arg);
  }

  const getCommandRecord = (input: string): ReactElement => {
    return (
      <div ref={(el) => (scrollRef.current = el)} className="terminal-command-record">
        <span className="terminal-prompt">{terminalPrompt}</span>
        <span>{input}</span>
      </div>
    )
  }

  const getHistory = (direction: "up" | "down") => {
    let updatedIndex;
    if (direction === "up") {
      updatedIndex = historyIndex === 0 ? 0 : historyIndex - 1;
    } else {
      updatedIndex =
        historyIndex === history.length ? history.length : historyIndex + 1;
    }
    setHistoryIndex(updatedIndex);
    return updatedIndex === history.length ? "" : history[updatedIndex];
  };

  const getAutocomplete = (input: string) => {
    const matchingCommands = allCommands.filter((c) => c.startsWith(input));
    if (matchingCommands.length === 1) {
      return matchingCommands[0];
    } else {
      const commandRecord = (
        <div
          ref={(el) => (scrollRef.current = el)}
          className="terminal-command-record"
        >
          <span className="terminal-prompt">{terminalPrompt}</span>{" "}
          <span>{input}</span>
        </div>
      );
      setOutput([...output, commandRecord, matchingCommands.join("    ")]);
      return input;
    }
  };

  const handleMissionAcceptance = (inputString: string) => {
    setIsMissionAcceptance(false);
    if(inputString==='y'){
      // we need to go to the connect wallet flow
      handleHeistCommand('yes - verifying manifests');
    }else{
      setIsHeist(false);
    }
  }

  const helpText = (
    <>
      <p>
        Heist Menu
      </p>
      <dl>
        <dt>Mission Briefing</dt>
        <dd>Review the latest intelligence for the heist.</dd>
        <dt>Connect Wallet</dt>
        <dd>Verify hack eligibility based on your wallet contents (read only).</dd>
        <dt>Start Heist</dt>
        <dd>Take action to carry out the heist.</dd>
        <dt>Abort</dt>
        <dd>Exit the heist and return to the top level mint terminal.</dd>
      </dl>
    </>
  );

  const printMenu = (input: string) => {
    const commandRecord = getCommandRecord(input);
    setOutput([
      ...output,
      commandRecord,
      <div className="terminal-command-output">
        {input!=='y' && <ErrorMessage command={input} heistError={true}/>}
        { helpText }
      </div>
    ]);
  }

  const commands: { [key in EchoCommand]: JSX.Element } = {
    help: ( helpText ),
    "mission briefing": (
      <>
        <p style={{fontWeight: 900, textDecorationLine: "underline"}}>Background</p>
        <p>You wouldn’t know it by looking at it, but security is tight. Our suspicions have been proven correct. It seems Mega Mobile has converted this old chip factory into a temporary depot under the cloak of darkness to aid and abet the Somnites. And they’ve thrown their latest hardware and network tech at it to beef up security. They know we’re watching, so it’s not going to be easy.</p>
        <p style={{fontWeight: 900, textDecorationLine: "underline"}}>Trojan Horse</p>
        <p>Friendly operatives have staked out the local and observed Mega Mobile techs disguised as chip company employees. Our plan is to infiltrate their systems by sending in renegade runners disguised as chip company workers to blend in with the Somnites and take over operations from the inside.</p>
        <p>Once inside, runners will need to install a keylogger and bash bunny into the onsite mainframe and set up a long range antenna to bypass the firewall so we can receive their files in The Hideout.</p>
        <p>Once we have the files we’ll send in renegades disguised as doritos dock workers to unload the crates. Loot from the warehouse will be available to heist by any runner who hacked the corresponding manifest.</p>
        <p>Once we’re done all the renegades that help with the heist will get a participation trophy (in the form of a poap) and we’ll toss the keys to the factory to one of those poap holders.</p>
        <p style={{fontWeight: 900, textDecorationLine: "underline"}}>We’ll need help from the following runners and bootjackers:</p>
        <p>bootjack manifests:</p>
        <ul>
          <li>Key Logger</li>
          <li>Bash Bunny</li>
          <li>Code Bomb</li>
          <li>Raspberry Pi 3</li>
          <li>Long Range Antenna</li>
          <li>Doritos Polo</li>
          <li>Doritos Windbreaker</li>
          <li>Doritos Mega City HQ</li>
        </ul>
        <p>Chain Runners:</p>
        <ul>
          <li>Pyramid Cap</li>
          <li>Funky Headband</li>
        </ul>
      </>
    )
  };

  const focusOnInput = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Tab") {
      // Prevent tab from moving focus
      event.preventDefault();
    }
    inputRef.current?.focus();
  };

  const handleHeistCommand = async (input: string) => {
    const commandRecord = getCommandRecord(input);

    let terminalOutput: JSX.Element;
    switch(input){
      case "abort":
        setIsHeist(false);
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <p>Exited heist.</p>
          </div>
        ]);
        return;
      case "mission briefing":
        terminalOutput = (
          <>
            <p style={{fontWeight: 900, textDecorationLine: "underline"}}>Background</p>
            <p>You wouldn’t know it by looking at it, but security is tight. Our suspicions have been proven correct. It seems Mega Mobile has converted this old chip factory into a temporary depot under the cloak of darkness to aid and abet the Somnites. And they’ve thrown their latest hardware and network tech at it to beef up security. They know we’re watching, so it’s not going to be easy.</p>
            <p style={{fontWeight: 900, textDecorationLine: "underline"}}>Trojan Horse</p>
            <p>Friendly operatives have staked out the local and observed Mega Mobile techs disguised as chip company employees. Our plan is to infiltrate their systems by sending in renegade runners disguised as chip company workers to blend in with the Somnites and take over operations from the inside.</p>
            <p>Once inside, runners will need to install a keylogger and bash bunny into the onsite mainframe and set up a long range antenna to bypass the firewall so we can receive their files in The Hideout.</p>
            <p>Once we have the files we’ll send in renegades disguised as doritos dock workers to unload the crates. Loot from the warehouse will be available to heist by any runner who hacked the corresponding manifest.</p>
            <p>Once we’re done all the renegades that help with the heist will get a participation trophy (in the form of a poap) and we’ll toss the keys to the factory to one of those poap holders.</p>
            <p style={{fontWeight: 900, textDecorationLine: "underline"}}>We’ll need help from the following runners and bootjackers:</p>
            <p>bootjack manifests:</p>
            <ul>
              <li>Key Logger</li>
              <li>Bash Bunny</li>
              <li>Code Bomb</li>
              <li>Raspberry Pi 3</li>
              <li>Long Range Antenna</li>
              <li>Doritos Polo</li>
              <li>Doritos Windbreaker</li>
              <li>Doritos Mega City HQ</li>
            </ul>
            <p>Chain Runners:</p>
            <ul>
              <li>Pyramid Cap</li>
              <li>Funky Headband</li>
            </ul>
          </>
        ) 
        break;
      case "yes - verifying manifests":
      case "connect wallet":
      case "cw":
        terminalOutput= (
          <>
            <p>
              Not Verified - Hack a Manifest / or 
              <a target="_blank" href="https://opensea.io/collection/bootjack" rel="noreferrer">Buy Manifest</a> or 
              <a href="https://opensea.io/collection/chain-runners-nft" target="_blank" rel="noreferrer">Buy Chain Runners</a>
            </p>
          </>
        );
        if(provider){
          setIsHeistConfirmation(true);
          // MORALIS based check and capture of data so that we'll know which tokens they hold
          const currentAddress = await provider.getSigner().getAddress();
          const manifestOptions: any = {
            address: currentAddress,
            token_address: getContractAddress()
          };
          // NOTE this is hardcoded to the chain runner mainnet adddress
          const runnerOptions: any = {
            address: currentAddress,
            token_address: '0x97597002980134beA46250Aa0510C9B90d87A587'
          }
          // set the query to run against the right network because even though it's a rinkeby server you have to override ETH
          if(process.env.REACT_APP_NETWORK==='RINKEBY'){
            manifestOptions.chain = 'rinkeby';
            runnerOptions.chain = 'rinkeby';
          }
          const manifestResponse = await Moralis.Web3API.account.getNFTsForContract(manifestOptions);
          const runnerResponse = await Moralis.Web3API.account.getNFTsForContract(runnerOptions);
          
          if(
            (manifestResponse.total && manifestResponse.total>0) ||
            (runnerResponse.total && runnerResponse.total>0)
          ){
            // save those for future use.
            setManifestList(manifestResponse.result);
            setRunnerList(runnerResponse.result);

            terminalOutput = (
              <>
                <p>Verified - Start Heist [y/n]</p>
              </>
            )
          }
        }
        break;
      // TODO add in logic to query the user for a username
      case "name prompt":
        setIsNamePrompt(true);
        terminalOutput = (
          <p>Enter Player Name</p>
        )
        break;
      case "start heist":
      case "objective complete":
        const listAttributes = getAttributesFromTokenLists(manifestList,runnerList);
        console.log('listAttributes',listAttributes);
        attributeRef.current = listAttributes;
        // const playerMetadata = await getTokenMetaDataForHolder(provider);
        setIsMissionSelection(true);
        // console.log('player metadata',playerMetadata);
        terminalOutput = (
          <>
            <ObjectiveStatus 
              guards={objectiveProgress.guards} 
              hardware={objectiveProgress.hardware} 
              crates={objectiveProgress.crates}
            />
            <p>Heist Roles - BYPASS GUARDS, PLANT HARDWARE, UNLOAD CRATES</p>
            <dt>BYPASS GUARDS</dt>
            <ul>
              { playerHasAttribute('Doritos Dust') && (<dd>Doritos Dust</dd>)}
              { playerHasAttribute('Doritos HQ Key Card') && (<dd>Doritos HQ Key Card</dd>)}
              { playerHasAttribute('Doritos Polo') && (<dd>Doritos Polo</dd>)}
              { playerHasAttribute('Dorito Windbreaker') && (<dd>Dorito Windbreaker</dd>)}
            </ul>
            <dt>PLANT HARDWARE</dt>
            <ul>
              { playerHasAttribute('Bash Bunny') && (<dd>Bash Bunny</dd>)}
              { playerHasAttribute('Code Bomb') && (<dd>Code Bomb</dd>)}
              { playerHasAttribute('Raspberry Pi 3') && (<dd>Raspberry Pi 3</dd>)}
              { playerHasAttribute('Long Range Antenna') && (<dd>Long Range Antenna</dd>)}
            </ul>
            <dt>UNLOAD CRATES</dt>
            <ul>
              { playerHasAttribute('Doritos Delivery Truck') && (<dd>Doritos Delivery Truck</dd>) }
              { playerHasAttribute('Dorito Hoodie') && (<dd>Dorito Hoodie</dd>)}
              { playerHasAttribute('Doritos Clipboard') && (<dd>Doritos Clipboard</dd>)}
              { playerHasAttribute('Cool Ranch Doritos') && (<dd>Cool Ranch Doritos</dd>)}
            </ul>
            <dt>MONITOR NETWORK COMMS</dt>
            <dd>bootjacker</dd>
          </>
        )
        break;
      case "bypass guards":
      case "plant hardware":
      case "unload crates":
        terminalOutput = (
          <p>Select an action based upon the attribute you will complete it with e.g. Doritos Dust</p>
        );
        break;
      default:
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            <ErrorMessage command="Invalid input" />
          </div>
        ]);
        printMenu(input);
        return;
    }
    setOutput([
      ...output,
      commandRecord,
      <div className="terminal-command-output">
        {terminalOutput}
      </div>
    ]);
  }

  const redirectToTwitter = () => {
    window.location = 'https://twitter.com/megamobileco';
  }

  const processCommand = async (input: string) => {
    console.log('process command',input);
    // Store a record of this command with a ref to allow us to scroll it into view.
    // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.
    const commandRecord = getCommandRecord(input);
    if(input==='exit'){
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          Aborting heist.
        </div>
      ]);
      setTimeout(() => setIsHeist(false), 3000);
    }

    // Add command to to history if the command is not empty
    if (input.trim()) {
      setHistory([...history, input]);
      setHistoryIndex(history.length + 1);
    }
    if(specialState==='anykey'){
      setHistory([...history,'<anykey>']);
      setHistoryIndex(history.length+1);
    }

    // Now process command, ignoring case
    const inputCommand = input.toLowerCase().trim();
    // preprocessing done. determine what we're doing
    if(isMissionAcceptance){
      handleMissionAcceptance(inputCommand);
      return;
    }
    if(isNamePrompt){
      setIsNamePrompt(false);
      setUsername(input);
      handleHeistCommand('start heist')
      return;
    }
    if(isHeistConfirmation){
      setIsHeistConfirmation(false)
      if(input!=='y'){
        redirectToTwitter();
      }else{
        handleHeistCommand('name prompt');
      }
      return;
    }else if(isMissionSelection){
      console.log('handling mission selection',input);
      const inputText = input.toLowerCase();
      const missionStory = getMissionStory(inputText);
      setActiveStory(missionStory);
      setStoryIndex(0);
      setIsMissionSelection(false);
      setOutput([
        ...output,
        commandRecord,
        renderStepText(missionStory?.steps[0].text)
      ]);
      return;
    }else if(specialState){
      switch(specialState){
        case "anykey":
          setSpecialState(null);
          handleHeistCommand('objective complete');
          break;
        case "cloak protocol":
          let cloakSuccesses = localSuccesses;
          let cloakFailures = localFailures;
          // do stuff; print ranodm values and expect inputs
          // check correct
          if(specialPrompt==='MARAJ'){
            if(input==='54df25g9jk2'){
              console.log('maraj success');
              // check for total success
              if(localSuccesses===9){
                // overall success TODO
                console.log('overall success');
                renderStoryCompletion(commandRecord);
                setSpecialState('anykey');
                return;
              }else{
                cloakSuccesses+=1;
                setLocalSuccesses(cloakSuccesses);
              }
            }else{
              if(localFailures===9){
                renderStoryFailure(commandRecord);
              }else{
                cloakFailures+=1;
                setLocalFailures(cloakFailures);
              }
            }
          }else{
            // maraj's value is a failure
            if(input==='/cloak'){
              console.log('MM success');
              // check for total success
              if(localSuccesses===9){
                // overall success TODO
                console.log('overall success');
                renderStoryCompletion(commandRecord);
                setSpecialState('anykey');
                return;
              }else{
                cloakSuccesses+=1;
                setLocalSuccesses(cloakSuccesses);
              }
            }else{
              if(localFailures===9){
                // total failure
                console.log('mission failure');
                renderStoryFailure(commandRecord);
              }else{
                cloakFailures+=1;
                setLocalFailures(cloakFailures);
              }
            }
          }
          // print next one
          const nextPrompt = getCloakPrompt();
          setSpecialPrompt(nextPrompt);
          setOutput([
            ...output,
            commandRecord,
            <div className="terminal-command-output">
              [{ cloakSuccesses } | { cloakFailures }] { nextPrompt }
            </div>
          ]);
          break;
        case "logjam":
          let logjamSuccesses = localSuccesses;
          let logjamFailures = localFailures;
          console.log('processing logjam',input);
          // logjam tbd
          if(specialPrompt==='RENEGADE'){
            // need to put in some gibberish
            if(input.length>5 && input!=='RENEGADE'){
              // success
              if(localSuccesses===9){
                // overall success TODO
                console.log('overall success');
                renderStoryCompletion(commandRecord);
                setSpecialState('anykey');
                return;
              }else{
                logjamSuccesses+=1;
                setLocalSuccesses(logjamSuccesses);
              }
            }else{
              if(localFailures===9){
                console.log('overall failure');
                renderStoryFailure(commandRecord);
              }else{
                logjamFailures+=1;
                setLocalFailures(logjamFailures);
              }
            }
          }else{
            // just hit enter?
            if(input.length===0){
              // success
              if(localSuccesses===9){
                // overall success TODO
                console.log('overall success');
                renderStoryCompletion(commandRecord);
                setSpecialState('anykey');
                return;
              }else{
                logjamSuccesses+=1;
                setLocalSuccesses(logjamSuccesses);
              }
            }else{
              if(localFailures===9){
                console.log('overall failure');
                renderStoryFailure(commandRecord);
              }else{
                logjamFailures+=1;
                setLocalFailures(logjamFailures);
              }
            }
          }
          // print next one
          const nextJam = getLogjamPrompt();
          setSpecialPrompt(nextJam);
          setOutput([
            ...output,
            commandRecord,
            <div className="terminal-command-output">
              [{ logjamSuccesses } | { logjamFailures }] { nextJam }
            </div>
          ]);
          break;
        case 'manifest-coordinates':
          console.log('checking coordinates');
          if(!activeStory?.title){
            console.log('not able to fetch active story title, returning');
            return;
          }
          if(!manifestList){
            console.log('no manifests loaded');
            return;
          }
          const coordinateSuccess = checkCoordinates(activeStory.title, input, manifestList);
          console.log('coordinates check?',coordinateSuccess);
          if(coordinateSuccess){
            // continue
            setOutput([
              ...output,
              commandRecord,
              <div className="terminal-command-output">
                {/* <p>{ activeStory.steps[storyIndex+1].text }</p> */}
                { renderStepText(activeStory.steps[storyIndex+1].text) }
              </div>,
            ]);
            // clean up special state
            setSpecialState(null);
            setStoryIndex(storyIndex+1);
          }else{
            // failure
            setOutput([
              ...output,
              commandRecord,
              <div className="terminal-command-output">Nothing to see here. Try another spot...</div>
            ])
          }
          break;
        case "cosplay a somnite":
          setOutput([
            ...output,
            commandRecord,
            <div className="terminal-command-output">
              <p>You're feeling confident with the shipping manifest in hand. These stupid Somnites will neer suspect you of using advanced cybernetics and bootjacked network cloaking to bypass their perceptual field filters.</p>
              <p>The COOL RANCH DORITOS are in sight.</p>
              <p>/ enact cloak...</p>
              <p>Security disabled...</p>
              <p>Pending authorization...</p>
              <p>Transaction complete.</p>
              <p>I can’t believe I got away with that. These Somnites really do think the Mega Mobile network can’t be hacked don’t they?</p>
              <p>These COOL RANCH DORITOS could be very valuable on the black market… I know some runners at The Mega City Underground that would pay a pretty penny for this thing... and who knows if the Renegades will even be able to pull off this crazy heist anyway…</p>
            </div>
          ]);
          // have to somehow set up for the response. 
          setStoryIndex(storyIndex+1);
          break;
      }
      return;
    }else if(activeStory){
      console.log('handle input for active story',activeStory,input);
      const storyOutput: StoryResponse = handleStoryInput(activeStory,storyIndex,activeNamedStep,input);
      console.log('story output is',storyOutput);
      // cop out to jump to a named step
      // most likely will end the proces via this if you start down this path
      if(activeStory.namedSteps && storyOutput.action.startsWith('namedStep: ')){
        // remove the 'namedStep: 
        const stepName = storyOutput.action.replace('namedStep: ', '');
        // console.log('on step',stepName);
        // console.log('activestory value',activeStory.namedSteps[stepName]);
        setActiveNamedStep(stepName);
        setOutput([
          ...output,
          commandRecord,
          <div className="terminal-command-output">
            { renderStepText(activeStory.namedSteps[stepName].text) }
          </div>
        ]);
      }else{
        // if we're not on a named step, we make sure it's empty
        // but if we're in an error state, keep it
        if(activeNamedStep && storyOutput.action!=='error'){
          setActiveNamedStep(undefined);
        }
        switch(storyOutput.action){
          case "advance":
            const currentIndex = storyIndex;
            setStoryIndex(storyIndex+1);
            setOutput([
              ...output,
              commandRecord,
              <div className="terminal-command-output">
                {/* <p>{ activeStory.steps[currentIndex+1].text }</p> */}
                { renderStepText(activeStory.steps[currentIndex+1].text) }
              </div>,
            ]);
            break;
          case "cloak protocol":
            const cloakPrompt = getCloakPrompt();
            setSpecialPrompt(cloakPrompt);
            setOutput([
              ...output,
              commandRecord,
              <div className="terminal-command-output">
                <p>{ storyOutput.text }</p>
                <p>{ cloakPrompt }</p>
              </div>,
            ]);
            setSpecialState('cloak protocol');
            break;
          case "logjam":
            const jamPrompt = getLogjamPrompt();
            setSpecialPrompt(jamPrompt);
            setOutput([
              ...output,
              commandRecord,
              <div className="terminal-command-output">
                <p>{ storyOutput.text }</p>
                <p>{ jamPrompt }</p>
              </div>,
            ]);
            setSpecialState('logjam');
            break;
          case "check coordinates":
            // locally set a value to be used in teh timeout
            const outputRef = [
              ...output,
              commandRecord,
              <div className="terminal-command-output">
                { renderStepText(storyOutput.text) }
              </div>
            ];
            setOutput(outputRef);

            setTimeout(() => {
              setOutput([
                ...outputRef,
                '',
                <div className="terminal-command-output">
                  <p>Where are you headed?</p>
                </div>
              ]);
              setSpecialState('manifest-coordinates');
            }, 5000);
            break;
          case "completion":
            // handleHeistCommand('objective complete');
            if(activeNamedStep){
              setActiveNamedStep(undefined);
            }
            renderStoryCompletion(commandRecord);
            break;
          case 'fail':
            if(activeNamedStep){
              setActiveNamedStep(undefined);
            }
            renderStoryFailure(commandRecord, storyOutput.text);
            break;
          case "error":
            console.log('error',storyOutput.text);
            break;
        }
      }
      return;
    }
    handleHeistCommand(inputCommand);
    if (!isValidCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <ErrorMessage command={inputCommand} />
        </div>,
      ]);
    } else if (isEchoCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>,
      ]);
    } else if (isUtilityCommand(inputCommand)) {
      switch (inputCommand) {
        case "clear": {
          setOutput([]);
          break;
        }
      }
    }
  };

  const playerHasAttribute = (attribute: string): boolean => {
    // use this to show all NFTs, but you also need to turn it on for location matching
    if(process.env.NODE_ENV==='development') return true;
    if(attributeRef.current.indexOf(attribute.toLowerCase())>-1){ return true; }
    return false;
  }

  const renderStoryFailure = (commandRecord: string|JSX.Element, alternateText?: string|string[]) => {    
    let processedText;
    if(alternateText){
      if(typeof alternateText==='string'){
        processedText = <dd>{alternateText}</dd>
      }else{
        processedText = (
          <>
            { alternateText.map((item) => <dd>{item}</dd>) }
          </>
        );
      }
    }
    setOutput([
      ...output,
      commandRecord,
      (
        <div className="terminal-command-output">
          <dt>OBJECTIVE FAILED</dt>
          <dd>{ processedText ?? "You've been caught trespassing"}</dd>
          <dd>HEIST PROGRESS +0%</dd>
          <dd>Game over</dd>
        </div>
      )
    ]);
    setTimeout(() => {
      setIsHeist(false)
    }, 5000);
  }

  const renderStoryCompletion = ( commandRecord: string|JSX.Element ) => {
    console.log('render story completion',activeStory);
    // not reasonable to get here with an undefined activeStory, but i guess possible
    if(!activeStory) return;
    const completionObject = activeStory.completion;
    const progressObject = {...objectiveProgress};
    progressObject[completionObject.progress.section] = completionObject.progress.value;
    setObjectiveProgress(progressObject);
    // reset counts
    setLocalFailures(0);
    setLocalSuccesses(0);
    setSpecialState('anykey');
    setOutput([
      ...output,
      commandRecord,
      (
        <div className="terminal-command-output">
          <dt>MISSION SUCCESS</dt>
          <dd>{completionObject.text}</dd>
          <dd>{completionObject.poapData}</dd>
          <dd>Press ENTER to continue...</dd>
        </div>
      )
    ]);
  }

  return (
    <div className="terminal-container" tabIndex={-1} onKeyDown={focusOnInput}>
      <div className="terminal-content">
        {banner && <Banner banner={banner} />}
        {welcomeMessage && (
          <WelcomeMessage message={welcomeMessage} inputRef={inputRef} skipAnimation={true} />

        )}
        <TerminalOutput outputs={output} />
        <InputArea
          setOutput={setOutput}
          processCommand={processCommand}
          getHistory={getHistory}
          getAutocomplete={getAutocomplete}
          inputRef={inputRef}
          terminalPrompt={terminalPrompt}
        />
      </div>
    </div>
  );
}

export default HeistTerminal;