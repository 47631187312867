import React from "react";

type StatusShadingProps = {
  value: number;
}
const StatusShading = (props: StatusShadingProps) => {
  const statusArray = [];
  for(let i=0; i<10; i++){
    const keyValue = `slash-${i}`
    if(props.value>i*10){
      statusArray.push(<span key={keyValue} className="terminal-glow">/</span>);
    }else{
      statusArray.push(<span key={keyValue}>/</span>);
    }
  }
  return (
    <>
      {statusArray}
    </>
  );
}

type ObjectiveStatusProps = {
  guards: number;
  hardware: number;
  crates: number;
};
const ObjectiveStatus = (props: ObjectiveStatusProps) => {
  return (
    <>
      <dt>BYPASS GUARDS</dt>
      <dd>[ {props.guards}% <StatusShading value={props.guards}/>]</dd>
      <dt>PLANT HARDWARE</dt>
      <dd>[ {props.hardware}% <StatusShading value={props.hardware}/>]</dd>
      <dt>UNLOAD CRATES</dt>
      <dd>[ {props.crates}% <StatusShading value={props.crates}/>]</dd>
    </>
  )
}

export default ObjectiveStatus;